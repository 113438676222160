import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

// Add additional plugins to dayjs
[
  relativeTime,
  calendar,
  utc,
  customParseFormat,
  isSameOrAfter,
].forEach(plugin => dayjs.extend(plugin))

export default dayjs
