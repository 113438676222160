<template>
  <div class="show-value">
    <label>
      {{ label }}
    </label>
    <div
      :class="`${color}--text`"
      :style="row ? 'display: inline-block; padding-left: 6px' : ''"
    >
      {{ value }}
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: null,
    },
    row: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
