<template>
  <v-text-field
    ref="textField"
    v-model="inputValue"
    v-mask="computedMask"
    :label="!labelTop ? label : null"
    :rules="computedRules"
    :counter="computedCounter"
    :clearable="isClearable"
    :clear-icon="icons.mdiClose"
    :placeholder="computedPlaceholder"
    :type="computedType"
    :inputmode="computedInputMode"
    :maxlength="computedMaxlength"
    :prepend-icon="prependIcon"
    :append-icon="computedAppendIcon"
    :append-outer-icon="appendOuterIcon"
    :prefix="prefix"
    :suffix="suffix"
    :hint="hint"
    dense
    :readonly="computedReadonly"
    :disabled="disabled"
    :hide-details="hideDetails"
    :single-line="singleLine || labelTop"
    :class="`${noteRequiredField && !disabled ? 'required-field' : ''} ${labelTop ? 'text-field-label-top' : ''}`"
    :outlined="outlined"
    @blur="handleBlur"
    @click="$emit('click', $event)"
    @input="inputEvent"
    @keyup.native="$emit('keyup', $event)"
    @click:append="appendIconClicked"
    @click:clear="$emit('input', null); $emit('click:clear', null)"
    @focus="$emit('focus', $event); if (noClick) $event.target.blur()"
    @keyup.enter="$emit('blur', $event)"
  >
    <template
      v-if="labelTop"
      #prepend
    >
      <strong
        v-if="noteRequiredField && !disabled"
        class="error--text"
      >*</strong>
      {{ label }}
    </template>
    <template
      v-if="noteRequiredField && !labelTop"
      #label
    >
      <strong
        v-if="!disabled"
        class="error--text"
      >*</strong>
      {{ label }}
    </template>
  </v-text-field>
</template>

<script>
/* eslint-disable no-eval */
import { mdiClose, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    numeric: {
      type: Boolean,
      default: false,
    },
    email: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: [String, Array, Function, Boolean],
      default: '',
    },
    rules: {
      type: [String, Boolean],
      default: null,
    },
    maxlength: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: [String, Boolean],
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    appendOuterIcon: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    notClearable: {
      type: Boolean,
      default: false,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    labelTop: {
      type: Boolean,
      default: false,
    },
    counter: {
      type: String,
      default: null,
    },
    readonlyValidate: {
      type: Boolean,
      default: false,
    },
    noClick: {
      type: Boolean,
      default: false,
    },
    password: {
      type: Boolean,
      default: false,
    },
    passwordVisible: {
      type: Boolean,
      default: false,
    },
    dateInput: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isPasswordVisible: this.passwordVisible,
      inputValue: this.value,
      icons: { mdiEyeOutline, mdiEyeOffOutline, mdiClose },
    }
  },
  computed: {
    noteRequiredField() {
      return this.required && !this.inputValue
    },
    isPasswordField() {
      return (this.password || this.rules === 'password' || this.label.substring(0, 8).toLowerCase() === 'password')
    },
    computedAppendIcon() {
      if (this.isPasswordField && this.inputValue) {
        return this.isPasswordVisible ? this.icons.mdiEyeOffOutline : this.icons.mdiEyeOutline
      }

      if (this.appendIcon !== false) {
        return this.appendIcon
      }

      return null
    },
    isClearable() {
      return !this.readonly && !this.notClearable
    },
    computedReadonly() {
      return this.readonly || !this.dateInput
    },
    computedType() {
      return !this.isPasswordField || this.isPasswordVisible ? 'text' : 'password'
    },
    computedInputMode() {
      if (this.numeric) return 'numeric'
      if (this.email) return 'email'

      return ''
    },
    computedPlaceholder() {
      if (this.placeholder !== null) {
        return this.placeholder
      }
      switch (this.rules) {
        case 'email': return 'john@example.com'
        case 'date': return 'MM/DD/YYYY'
        default: return this.placeholder
      }
    },
    computedRules() {
      let rulesArray = []
      if (this.disabled || (this.readonly && !this.readonlyValidate)) return rulesArray
      if (this.rules) {
        rulesArray = rulesArray.concat(eval(`this.$validationRules.${this.rules}`))
      }
      if (this.required) {
        rulesArray = rulesArray.concat(this.$validationRules.required)
      }
      if (this.counter) {
        rulesArray = rulesArray.concat(eval(`this.$validationRules.maxLength(${this.counter})`))
      }

      return rulesArray
    },
    computedMask() {
      if (this.mask) return this.mask
      if (this.maxlength) return 'X'.repeat(this.maxlength)
      if (this.counter) return 'X'.repeat(this.counter)

      return null
    },
    computedCounter() {
      if (this.disabled || this.readonly) return false
      if (this.counter) return this.counter

      return false
    },
    computedMaxlength() {
      if (this.maxlength) return this.maxlength
      if (this.counter) return this.counter

      return false
    },
  },
  watch: {
    value() {
      this.inputValue = this.value
    },
    passwordVisible() {
      this.isPasswordVisible = this.passwordVisible
    },
  },
  methods: {
    handleBlur(event) {
      if (event.target.value === '' || event.target.value === null) {
        this.$emit('input', '')
      }
      this.$emit('blur', event)
    },
    inputEvent(event) {
      // This accommodates something strange which happens when using the date picker
      if (!this.dateInput) {
        this.inputValue = this.value
        this.$emit('input', this.value)
      } else {
        this.$emit('input', event)
      }
    },
    appendIconClicked(event) {
      if (this.isPasswordField) {
        this.isPasswordVisible = !this.isPasswordVisible
        this.$emit('update:passwordVisible', this.isPasswordVisible)
      }
      this.$emit('click:append', event)
      this.$refs.textField.focus()
    },
    validate() {
      this.$refs.textField.validate()
    },
  },
}
</script>

<style lang="scss">
.text-field-label-top {
  display: block;
  .v-input__prepend-outer {
    display: block;
    margin-top: 4px;
    margin-right: 0;
    padding-bottom: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--v-secondary-darken2)
  }
}
</style>
