/* eslint-disable no-unused-vars */
import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    routeHistory: [],
  },
  getters: {
    getField,
    routeHistory: state => state.routeHistory,
    currentRoute: state => state.routeHistory[0],
    hasPreviousRoute: state => state.routeHistory.length > 1,
    lastRoute(state) {
      return state.routeHistory.length > 1 ? state.routeHistory[1] : {}
    },
  },
  mutations: {
    updateField,
    addRoute: (state, value) => {
      const route = { ...value }
      delete route.matched
      state.routeHistory.unshift({ ...route })
      while (state.routeHistory.length > 11) {
        state.routeHistory.pop()
      }
    },
    updateRoute: (state, value) => {
      const route = { ...value }
      delete route.matched
      state.routeHistory[0] = { ...route }
    },
    updateQuery: (state, query = false) => {
      if (query) {
        state.routeHistory[0].query = { ...query }
      }
      state.routeHistory[0].path = window.location.pathname
      state.routeHistory[0].fullPath = `${window.location.pathname}${window.location.search}`
    },
    routeBack: state => {
      state.routeHistory.shift()
      state.routeHistory.shift()
    },
  },
  actions: {
    // This exists because router.back() doesn't respect beforeRouteLeave for some reason
    back({ commit, getters, state }) {
      const { hasPreviousRoute } = getters
      const backRoute = hasPreviousRoute ? { ...state.routeHistory[1] } : { name: 'home' }
      Vue.router.push(backRoute)
        .then(() => {
          if (hasPreviousRoute) {
            commit('routeBack')
          } else {
            commit('updateRoute', Vue.router.currentRoute)
          }
        })
        .catch(() => {})
    },
  },
}
