import dayjs from '@/plugins/dayjs'
import Vue from 'vue'

Object.defineProperties(Vue.prototype, {
  $date: {
    get() {
      return dayjs
    },
  },
})
