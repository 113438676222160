 <!-- eslint-disable vue/no-v-html -->
<template>
  <v-dialog
    :key="index"
    v-model="dialog.visible"
    :width="dialog.large ? 750: 500"
    persistent
    @click:outside="clickOutside()"
  >
    <v-card class="confirmation-dialog">
      <v-card-title>
        <v-icon
          v-if="computedTitleIcon"
          :color="dialog.titleIconColor"
          class="mr-2"
        >
          {{ computedTitleIcon }}
        </v-icon>
        {{ $custom.toTitleCase(dialog.title) }}
      </v-card-title>

      <v-card-subtitle
        v-if="computedSubTitle !== ''"
      >
        {{ computedSubTitle }}
      </v-card-subtitle>

      <v-card-text v-if="dialog.isDuplicateConfirmation">
        <component
          :is="dialog.passedComponent"
          :patient-data="dialog"
          @update:selectedDuplicatePatient="updateSelectedDuplicatePatient"
        ></component>
      </v-card-text>
      <v-card-text
        v-else-if="dialog.html"
        v-html="dialog.body"
      ></v-card-text>
      <v-card-text v-else>
        <p>
          {{ dialog.body }}
        </p>
      </v-card-text>

      <text-field
        v-if="dialog.isResetStorageConfirmation"
        v-model="dialog.resetStorageConfirmation"
        class="col-sm-12 px-6"
        :label='"Type \"delete all my records\" to proceed."'
        :label-top="true"
      ></text-field>

      <v-card-actions>
        <!-- cancel action -->
        <btn
          v-if="dialog.cancel !== false"
          :label="dialog.cancel"
          :color="dialog.cancelColor"
          :icon="computedCancelIcon"
          @click="buttonClick(false)"
        ></btn>

        <v-spacer></v-spacer>

        <!-- confirm action -->
        <btn
          :disabled="dialog.resetStorageConfirmation !== 'delete all my records' && dialog.isResetStorageConfirmation"
          :label="dialog.confirm"
          :color="dialog.confirmColor"
          :icon="computedConfirmIcon"
          @click="buttonClick(true)"
        ></btn>
      </v-card-actions>

      <!-- To generate error logs below the confirmation button.-->
      <!-- TODO: Generate computed method to add text to the textarea as default and readonly.
                 Possible <<<Details button? -->
      <!--<v-card-text>
        <v-textarea
          bg-color="grey-lighten-2"
          color="black"
          :prepend-inner-icon="computedWarningIcon"
          no-resize
        >
        </v-textarea>
      </v-card-text> -->
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiAlert,
  mdiAlertOctagon,
  mdiCancel, mdiCheck, mdiClose,
  mdiInformation, mdiThumbUp,
} from '@mdi/js'

const defaultDialog = {
  visible: false,
  persistent: true,
  large: false,
  title: '',
  titleIcon: false,
  titleIconColor: null,
  subTitle: '',
  body: '',
  html: false,
  cancel: 'Cancel',
  cancelIcon: null,
  cancelColor: 'secondary',
  confirm: 'Okay',
  confirmIcon: null,
  confirmColor: 'primary',
  logoutTimer: false,
  isResetStorageConfirmation: false,
  resetStorageConfirmation: null,
}

export default {
  data() {
    return {
      reject: null,
      resolve: null,
      index: 0,
      logoutTimerInterval: null,
      logoutTimerRefresh: 0,
      duplicatePatients: null,
      selectedDuplicatePatient: null,
      dialog: {},
      icons: {
        mdiAlert, mdiCancel, mdiCheck, mdiClose, mdiAlertOctagon, mdiInformation, mdiThumbUp,
      },
    }
  },
  computed: {
    computedCancelIcon() {
      if (this.dialog.cancelIcon) return this.dialog.cancelIcon
      if (this.dialog.cancel?.substring(0, 2) === 'No') return this.icons.mdiClose

      return this.icons.mdiCancel
    },
    computedConfirmIcon() {
      if (this.dialog.confirmIcon) return this.dialog.confirmIcon

      switch (this.dialog.confirmColor) {
        case 'error': return this.icons.mdiAlertOctagon
        case 'warning': return this.icons.mdiAlert
        case 'success': return this.icons.mdiThumbUp
        default: return this.icons.mdiCheck
      }
    },
    computedTitleIcon() {
      if (this.dialog.titleIcon) return this.dialog.titleIcon

      switch (this.dialog.titleIconColor) {
        case 'error': return this.icons.mdiAlertOctagon
        case 'warning': return this.icons.mdiAlert
        case 'info': return this.icons.mdiInformation
        case 'success': return this.icons.mdiThumbUp
        default: return false
      }
    },
    computedSubTitle() {
      if (this.dialog.logoutTimer) {
        // eslint-disable-next-line no-unused-expressions
        this.logoutTimerRefresh
        const now = Math.floor(Date.now() / 1000)
        const expireMinutes = String(Math.floor((this.$store.getters['auth/getSessionExpiration'] - now) / 60)).padStart(2, '0')
        const expireSeconds = String((this.$store.getters['auth/getSessionExpiration'] - now) % 60).padStart(2, '0')

        return `Your session will expire in: ${expireMinutes}:${expireSeconds}`
      }

      return this.dialog.subTitle
    },
  },
  methods: {
    open(options) {
      this.dialog = {
        ...defaultDialog,
        ...options,
      }

      if (this.dialog.isDuplicateConfirmation !== undefined) {
        // console.log(this.dialog.body.duplicatePatients)
        this.duplicatePatients = this.dialog.body.duplicatePatients
      }

      // Open the dialog
      this.dialog.visible = true

      clearInterval(this.logoutTimerInterval)
      if (this.dialog.logoutTimer) {
        this.logoutTimerInterval = setInterval(() => {
          this.logoutTimerRefresh += 1
          const now = Math.floor(Date.now() / 1000)
          if (
            this.$authUser.user()
            && this.$store.getters['auth/getSessionExpiration'] > 0
            && now >= this.$store.getters['auth/getSessionExpiration']
          ) {
            // Session expired, logout
            this.$router.push({
              name: 'login',
              query: { notify: 'You\'ve been logged out due to inactivity.', color: 'error', timeout: -1 },
            }).catch(() => {})
          }
        }, 1000)
      }

      // Return a promise that resolves when the dialog is closed
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    updateSelectedDuplicatePatient(selectedDupPatient) {
      this.selectedDuplicatePatient = selectedDupPatient
    },
    buttonClick(value) {
      const resolveValue = !value && this.dialog.isDuplicateConfirmation ? this.selectedDuplicatePatient : value

      this.resolve(resolveValue)

      // Reset the dialog state (also closes the dialog)
      this.dialog = {
        ...this.defaultDialog,
      }
      clearInterval(this.logoutTimerInterval)
      this.index += 1
    },
    clickOutside() {
      if (!this.dialog.persistent) {
        this.buttonClick(false)
      }
    },
  },
}
</script>

<style lang="scss">
.v-card.confirmation-dialog {
  > .v-card__subtitle {
    padding-left: 20px !important;
    margin-top: -10px !important;
    font-size: 1em;
  }
  > .v-card__text {
    font-size: 1em;
  }
}
</style>
