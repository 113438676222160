import {
  mdiAccountClock,
  mdiAccountGroup,
  mdiAccountMultiple,
  mdiAccountMultiplePlus,
  mdiAccountPlus,
  mdiAccountSearch,
  mdiBookmark,
  mdiCommentTextMultiple,
  mdiHome,
  mdiHospitalBuilding,
} from '@mdi/js'
import Vue from 'vue'

export default [
  {
    title: 'Home',
    icon: mdiHome,
    to: 'home',
  },
  {
    title: 'Facilities',
    icon: mdiHospitalBuilding,
    to: 'facilities',
  },
  {
    title: 'My Facilities Lists',
    icon: mdiBookmark,
    to: 'facilities-playlist',
  },
  {
    title: 'Comments',
    icon: mdiCommentTextMultiple,
    badge: Vue.store.state.comments.items.filter(x => !x.is_read).length,
    badgeColor: 'error',
    to: 'list-comments',
  },

  {
    subheader: 'Patient Profiles',
  },
  {
    title: 'List Patient Profiles',
    icon: mdiAccountGroup,
    to: 'list-patients',
  },
  {
    title: 'Find Patient Profile',
    icon: mdiAccountSearch,
    to: { name: 'find-patient' },
  },
  {
    title: 'Create Patient Profile',
    icon: mdiAccountPlus,
    to: { name: 'add-patient' },
  },

  {
    subheader: 'Encounter Notes',
  },
  {
    title: 'List Encounters',
    icon: mdiAccountMultiple,
    to: 'list-encounters',
  },
  {
    title: 'Create Encounter',
    icon: mdiAccountMultiplePlus,
    to: 'encounter-patient-find',
  },

  {
    subheader: 'Quick Entry ',
  },
  {
    title: 'Quick Entry',
    icon: mdiAccountClock,
    to: 'quick-entry',
  },

  /*
  {
    title: 'Create Quick Entry',
    icon: mdiClockPlus,
    to: 'quick-entry-patient-find',
  },
  */

  /*
  {
    title: 'Quick Notes',
    icon: mdiAccountClock,
    to: 'quick-notes',
  },
  */
]
