import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'

const initialState = {
  items: [],
  error: false,
}

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  getters: {
    getField,
    getById(state) {
      return (id, idField = 'id') => state.items.find(item => item[idField] === id) || {}
    },
    users(state) {
      return state.error ? [] : state.items
    },
  },
  mutations: {
    updateField,
    SET_USER_PASSWORD(state, { email, password }) {
      // Find the user index
      const index = state.items.findIndex(x => x.email === email)

      // Set the password
      if (index !== -1) state.items[index].password = password
    },
    RESET_STATE: state => {
      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })
    },
    SET_USERS(state, { data }) {
      state.items = data
    },
  },
  actions: {
    loadUsers({ commit }) {
      commit('encounters/SET_SYNCING_TXT', `Fetching user data`, { root: true})
      return Vue.axios.get('users.json')
        .then(response => {
          if (response.data.status === 'Success') {
            commit('SET_USERS', Vue.prototype.$custom.deepFreeze(response.data))

            return true
          }

          return response.data.message ? response.data.message : 'Download users - Unknown error'
        })
        .catch(e => Vue.prototype.$custom.processCommError(e, 'download users'))
    },
    updatePassword({ commit }, { email, password }) {
      // TODO: Convert reset password to server-side
      commit('SET_USER_PASSWORD', { email, password })
    },
  },
}
