<template>
  <v-menu
    ref="userMenu"
    offset-y
    left
    nudge-bottom="14"
    min-width="245"
    content-class="user-profile-menu-content"
    transition="slide-y-transition"
  >
    <template #activator="{ on, attrs }">
      <v-row
        class="pr-3 pt-2"
      >
        <div>
          <div class="text--primary font-weight-semibold mb-n1">
            {{ user.name }}
          </div>
          <small class="text--disabled text-capitalize">{{ user.type }}</small>
        </div>
        <v-badge
          :color="isOnline ? 'success' : 'error'"
          class="avatar ms-4"
          offset-y="10"
          overlap
          dot
        >
          <v-badge
            :content="newComments"
            :value="newComments"
            color="error"
            offset-x="8"
            bordered
            overlap
            bottom
          >
            <v-avatar
              size="40px"
              v-bind="attrs"
              color="primary"
              class="v-avatar-light-bg primary--text"
              v-on="on"
            >
              <v-img :src="require(`@/assets/images/avatars/${user.avatar}.png`)"></v-img>
            </v-avatar>
          </v-badge>
        </v-badge>
      </v-row>
    </template>
    <v-list>
      <!--
      <div class="pb-3 pt-2 ms-4">
        <v-avatar
          size="40px"
          color="primary"
          class="v-avatar-light-bg primary--text"
        >
          <v-img :src="require(`@/assets/images/avatars/${user.avatar}.png`)"></v-img>
        </v-avatar>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ user.name }}
          </span>
          <small class="text--disabled text-capitalize">{{ user.type }}</small>
        </div>
      </div>

      <v-divider></v-divider>
      -->

      <v-list-item @click="homeRoute()">
        <icon-value :icon="icons.mdiHome">
          Home
        </icon-value>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item @click="placesOfServiceRoute()">
        <icon-value :icon="icons.mdiHospitalBuilding">
          Facilities
        </icon-value>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item @click="commentsRoute()">
        <icon-value :icon="icons.mdiCommentTextMultiple">
          Comments
        </icon-value>
        <v-list-item-action>
          <v-badge
            :content="newComments"
            :value="newComments"
            color="error"
            class="pl-4"
            inline
          ></v-badge>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item @click.native.prevent.stop.capture="toggleDarkMode()">
        <icon-value
          :icon="icons.mdiBrightness4"
          class="moon"
        >
          Dark Mode
        </icon-value>
        <div class="pl-3 pt-1">
          <v-icon style="vertical-align: text-bottom; width: 17px">
            {{ icons.mdiBrightness7 }}
          </v-icon>
          <v-switch
            v-model="user.darkMode"
            hide-details
            style="display: inline-block"
          ></v-switch>
          <v-icon
            class="moon"
            style="vertical-align: text-bottom; width: 17px; margin-left: -4px"
          >
            {{ icons.mdiBrightness2 }}
          </v-icon>
        </div>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item
        rem-v-if="$authUser.user() && $authUser.user().is_superuser"
        @click="resetStorage()"
      >
        <icon-value :icon="icons.mdiDeleteForever">
          Reset Local Storage
        </icon-value>
      </v-list-item>

      <v-divider></v-divider>

      <!-- Not sure this is really even useful, maybe remove -->
      <v-list-item
        :disabled="!isHomePage"
        @click="reload()"
      >
        <icon-value
          :disabled="!isHomePage"
          :icon="icons.mdiRefreshCircle"
        >
          Reload Page
        </icon-value>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item @click="logoutClick()">
        <icon-value :icon="icons.mdiLogout">
          Logout
        </icon-value>
      </v-list-item>

      <v-divider v-if="$authUser.user().is_superuser"></v-divider>

      <v-list-item
        v-if="$authUser.user().is_superuser"
        @click="debugPageRoute()"
      >
        <icon-value :icon="icons.mdiDebugStepOver">
          Debug Page
        </icon-value>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item
        class="secondary--text mr-4"
        style="justify-content: center"
        @click.native.prevent.stop.capture="i == i"
      >
        {{ isDevMode ? 'Dev Mode' : '' }}
        v{{ appVersion }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiDebugStepOver, mdiLogout, mdiHome, mdiDeleteForever, mdiCommentTextMultiple, mdiReload, mdiBrightness4, mdiBrightness7, mdiBrightness2, mdiRefreshCircle, mdiHospitalBuilding,
} from '@mdi/js'
import themeConfig from '@themeConfig'
import { mapGetters, mapState } from 'vuex'
import ResetStorage from '@/mixins/ResetStorage'

export default {
  mixins: [ResetStorage],
  setup() {
    return {
      appVersion: themeConfig.app.version,
      icons: {
        mdiDebugStepOver, mdiLogout, mdiHome, mdiDeleteForever, mdiCommentTextMultiple, mdiReload, mdiBrightness4, mdiBrightness7, mdiBrightness2, mdiRefreshCircle, mdiHospitalBuilding,
      },
    }
  },
  computed: {
    ...mapGetters(['isOnline']),
    ...mapGetters('auth', ['user']),
    ...mapGetters('route', ['currentRoute']),
    ...mapState('comments', { comments: 'items' }),
    newComments() {
      return this.comments.filter(x => !x.is_read).length
    },
    isHomePage() {
      return this.isOnline && this.currentRoute.name === 'home'
    },
    isDevMode() {
      return process.env.NODE_ENV === 'development'
    },
  },
  methods: {
    toggleDarkMode() {
      this.$store.dispatch('auth/setDarkMode', !this.user.darkMode)
    },
    logoutClick() {
      this.$refs.userMenu.isActive = false
      this.$root.confirm({
        title: 'Logout?',
        subTitle: 'This process will delete any unsaved form data!',
        body: 'Are you sure you wish to logout?',
        cancel: 'No, Stay Logged In',
        confirm: 'Yes, Logout',
        confirmColor: 'error',
        confirmIcon: this.icons.mdiLogout,
      }).then(result => {
        if (result) {
          this.$store.dispatch('auth/logout')
        }
      })
    },
    homeRoute() {
      if (this.$router.currentRoute.name !== 'home') this.$router.push({ name: 'home' })
    },
    placesOfServiceRoute() {
      if (this.$router.currentRoute.name !== 'facilities') this.$router.push({ name: 'facilities' })
    },
    commentsRoute() {
      if (this.$router.currentRoute.name !== 'list-comments') this.$router.push({ name: 'list-comments' })
    },
    debugPageRoute() {
      if (this.$router.currentRoute.name !== 'debugPage') this.$router.push({ name: 'debugPage' })
    },
    reload() {
      // Not sure this is really even useful, maybe remove?
      window.location.reload()

      // Reload is only available on the home page, so this confirmation isn't required at this time
      /*
      this.$root.confirm({
        title: 'Reload site?',
        body: 'Current changes you made may not be saved.',
        confirm: 'Reload',
        confirmColor: 'error',
        confirmIcon: this.icons.mdiReload,
      }).then(result => {
        if (result === true) {
          window.location.reload()
        }
      })
      */
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
    justify-content: space-between;
    padding: 0 0 0 16px;
  }
}
</style>
