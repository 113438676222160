import '@/components/elements'
import '@/plugins/custom'
import '@/plugins/date'
import '@/plugins/fragment'
import http from '@/plugins/http'
import '@/plugins/lodash'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import VueMask from 'v-mask'
import Vue from 'vue'
import App from './App.vue'
import auth from './plugins/auth'
import validation from './plugins/validation'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// Custom Plugins
Vue.use(validation)
Vue.use(VueMask)

const app = new Vue({
  http,
  router,
  store,
  config: { auth },
  vuetify,
  render: h => h(App),
}).$mount('#app')

if (window.Cypress) {
  window.app = app
}
