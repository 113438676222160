/* eslint-disable no-unused-vars */
import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'

const initialState = {
  items: [],
  error: false,
  isSynced: false,
}

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  getters: {
    getField,
    getById(state) {
      return (id, idField = 'id') => state.items.find(item => item[idField] === id) || {}
    },
    comments(state) {
      if (state.error) return []

      return state.items
    },
    commentCount(state) {
      if (state.error) return []

      return state.items.length
    },
    commentsToSync: state => state.items.filter(comment => (comment.updated || comment.new)),
  },
  mutations: {
    updateCommentSync: (state, value) => {
      // Find the comment index
      const index = state.items.findIndex(x => x.id === value.id)

      // Update patient values
      state.items[index] = { ...value }

      state.isSynced = false
    },
    updateField,
    RESET_STATE: state => {
      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })
    },
    SET_COMMENTS(state, { data }) {
      if (state.items.length === 0) {
        state.items = data.comments
      } else {
        const newCommentsList = data.comments.filter(commentNew => !state.items.some(commentOld => commentOld.id === commentNew.id))
        if (newCommentsList.length > 0) {
          console.log(newCommentsList.length > 1 ? 'New Comments Found' : 'New Comment Found: ', newCommentsList.length)
        } else if (state.items.some(comment => !(!comment.updated && !comment.new))) {
        } else {
        }

        state.items.push(...newCommentsList)
      }
    },
    ADD_COMMENT: (state, value) => {
      const newValue = {
        ...value,
        new: true,
      }
      state.items.push(newValue)
    },
  },
  actions: {
    syncComment({ commit }, comment) {
      return Vue.axios.put(`comments/${comment.id}.json`, comment)
        .then(response => ((response.data.status === 'Error' && response.data.message)
          ? response.data.message
          : response.data.status === 'Success'))
        .catch(e => Vue.prototype.$custom.processCommError(e, 'upload comment'))
    },
    postComment({ commit }, comment) {
      return Vue.axios.post('comments.json', comment)
        .then(response => ((response.data.status === 'Error' && response.data.message)
          ? response.data.message
          : response.data.status === 'Success'))
        .catch(e => Vue.prototype.$custom.processCommError(e, 'post comment'))
    },
    loadComments({ commit, getters }) {
      commit('encounters/SET_SYNCING_TXT', `Fetching comments`, { root: true})
      return Vue.axios.get('comments.json')
        .then(response => {
          if (response.data.status === 'Success') {
            commit('SET_COMMENTS', response)

            return true
          }

          return response
        })
        .catch(e => Vue.prototype.$custom.processCommError(e, 'download comments'))
    },
  },
}
