<template>
  <div class="py-0">
    <v-menu
      v-model="displayCalendar"
      :close-on-content-click="false"
      transition="slide-y-transition"
      offset-y
      left
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <date-input
          v-model="inputValue"
          v-bind="attrs"
          :label="label"
          :rules="computedRules"
          :mask="dateMask"
          :min="min"
          :max="max"
          :not-clearable="!clearable"
          :clear-icon="icons.mdiClose"
          :disabled="disabled"
          :hide-details="hideDetails"
          :append-icon="icons.mdiCalendarMonth"
          :required="required"
          :class="`${noteRequiredField && !disabled ? 'required-field' : ''} ${noOutline ? 'no-outline' : ''}`"
          :outlined="!noOutline"
          :date-input="dateInput"
          v-on="on"
          @blur="$emit('blur', $event)"
          @click="$emit('click', $event)"
          @click:clear="$emit('input', null); $emit('click:clear', null)"
          @click:append="on.click"
          @keyup.native="$emit('keyup', $event)"
          @input="$emit('input', selectedDate)"
        >
          <template
            v-if="noteRequiredField"
            #label
          >
            <strong
              class="error--text"
            >*</strong>
            {{ label }}
          </template>
        </date-input>
      </template>
      <v-date-picker
        v-model="selectedDate"
        :min="min"
        :max="max"
        no-title
        scrollable
        @input="dateSelect($event)"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
/* eslint-disable no-eval */
import { mdiCalendarMonth, mdiClose } from '@mdi/js'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    max: {
      type: String,
      default: null,
    },
    min: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    noOutline: {
      type: Boolean,
      default: false,
    },
    dateInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedDate: this.dateDB(this.value),
      inputValue: this.dateUS(this.value),
      displayCalendar: false,
      icons: { mdiCalendarMonth, mdiClose },
      dateMask: [/[0-9]/, /[0-9|/|-]/, /[0-9|/|-]/, /[0-9]/, /[0-9|/|-]/, /[0-9|/|-]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    }
  },
  computed: {
    noteRequiredField() {
      return this.required && this.inputValue?.length !== 10
    },
    computedRules() {
      return this.rules ? this.rules : 'date'
    },
  },
  watch: {
    value() {
      this.selectedDate = this.dateDB(this.value)
      this.inputValue = this.dateUS(this.value)
    },
  },
  methods: {
    dateSelect(event) {
      this.displayCalendar = false
      this.$emit('input', event)
    },
    dateDB(date) {
      return this.$custom.isDateValid(date) ? this.$date(date).format('YYYY-MM-DD') : null
    },
    dateUS(date) {
      return this.$custom.isDateValid(date) ? this.$date(date).format('MM/DD/YYYY') : null
    },
  },
}
</script>
