import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x'
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x'
import auth from '@websanova/vue-auth/src/v2'
import Vue from 'vue'
import driverJwt from './drivers/jwt'

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router,
  },
  drivers: {
    auth: driverJwt,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    rolesKey: 'roles',
    loginData: { url: 'jwt/login' },
    refreshData: { url: 'jwt/refresh', enabled: false },

    logoutData: { url: 'jwt/logout', redirect: false },
    fetchData: { url: 'users/me.json', enabled: window.navigator.onLine },
    authRedirect: { path: '/login' },
    refreshTokenName: 'auth_token_refresh',

    forbiddenRedirect() {
      // Force "new" users to the Account Setup route
      if (Vue.auth.check('new')) {
        return '/login'
      }

      return '/login'
    },
  },
})

export default {}
