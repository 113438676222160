import cloneDeep from 'lodash/cloneDeep'
import compact from 'lodash/compact'
import map from 'lodash/map'
import xor from 'lodash/xor'
import Vue from 'vue'

Object.defineProperties(Vue.prototype, {
  $lodash: {
    get() {
      return {
        cloneDeep,
        compact,
        map,
        xor,
      }
    },
  },
})
