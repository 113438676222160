<template>
  <v-btn
    :disabled="computedDisabled"
    :color="color"
    :small="small"
    :x-small="xSmall"
    :plain="plain"
    :block="block"
    :type="type"
    :text="text"
    :outlined="outlined"
    :loading="loading"
    :class="computedClass"
    @click="$emit('click', $event)"
  >
    <v-icon
      v-if="icon"
      left
    >
      {{ icon }}
    </v-icon>
    <slot>{{ label }}</slot>
  </v-btn>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: [String, Boolean],
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    offlineDisable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapFields(['online']),
    computedDisabled() {
      return this.disabled || (this.offlineDisable && !this.online)
    },
    computedClass() {
      return this.offlineDisable && !this.online ? 'offline' : ''
    },
  },
  methods: {
  },
}
</script>

<style lang="scss">
.v-btn.offline:after {
  content:url("data:image/svg+xml,<svg style='fill:white' viewBox='-2 -2 30 30' xmlns='http://www.w3.org/2000/svg'><path d='M7.73,10L15.73,18H6A4,4 0 0,1 2,14A4,4 0 0,1 6,10M3,5.27L5.75,8C2.56,8.15 0,10.77 0,14A6,6 0 0,0 6,20H17.73L19.73,22L21,20.73L4.27,4M19.35,10.03C18.67,6.59 15.64,4 12,4C10.5,4 9.15,4.43 8,5.17L9.45,6.63C10.21,6.23 11.08,6 12,6A5.5,5.5 0 0,1 17.5,11.5V12H19A3,3 0 0,1 22,15C22,16.13 21.36,17.11 20.44,17.62L21.89,19.07C23.16,18.16 24,16.68 24,15C24,12.36 21.95,10.22 19.35,10.03Z'></path></svg>");
  position: absolute;
  top: -6px;
  right: -6px;
  width: 24px;
  height: 24px;
  background-color: var(--v-error-base);
  border: 2px solid map-get($shades, 'white');
  border-radius: 50%;
}
.v-btn.v-btn--plain.offline:after {
  top: -2px;
  right: -2px;
  width: 22px;
  height: 22px;
  background-color: var(--v-secondary-lighten3);
}
</style>
