<template>
  <v-chip
    v-if="showChip"
    :class="`v-chip-light-bg ${color}--text`"
    :small="small"
    :close="close"
    :close-icon="closeIcon"
    :icon-color="iconColor"
    :label="label"
    @click:close="onClose"
  >
    <v-icon
      class="mr-1"
      :color="color"
      :small="small"
    >
      {{ icon }}
    </v-icon>
    <slot>
      {{ value }}
    </slot>
  </v-chip>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    label: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: 'primary',
    },
    color: {
      type: String,
      default: 'primary',
    },
    close: {
      type: Boolean,
      default: false,
    },
    closeIcon: {
      type: String,
      default: null,
    },
    isLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showChip: true,
    }
  },
  methods: {
    onClose() {
      this.showChip = false
    },
  },
}
</script>
