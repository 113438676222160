<template>
  <div>
    <component
      :is="resolveLayoutVariant"
      :class="`${user.darkMode ? 'dark-mode' : ''} skin-variant--${appSkinVariant}`"
    >
      <transition
        :name="appRouteTransition"
        mode="out-in"
        appear
      >
        <router-view
          :key="$route.fullPath"
        ></router-view>
      </transition>
      <notify></notify>
      <ConfirmationDialog ref="confirm"></ConfirmationDialog>
    </component>
    <v-overlay
      :value="loading || syncing"
      opacity="0.8"
      z-index="500"
      class="text-center"
      style="text-align: center"
    >
      <v-progress-circular
        v-if="!syncing || !progressValueSet"
        indeterminate
        size="64"
        width="7"
        color="primary"
        class="load-sync-progress"
      ></v-progress-circular>
      <p
        v-if="!syncing || !progressValueSet"
        class="loading-message"
      >
        Loading...
      </p>

      <v-progress-circular
        v-if="syncing && progressValueSet"
        rotate="180"
        size="80"
        width="7"
        color="primary"
        :value="progressValue"
        class="load-sync-progress"
      >
        {{ progressValue }}%
      </v-progress-circular>
      <p
        v-if="syncing && progressValueSet"
        class="loading-message"
      >
        {{ syncingTxt ? syncingTxt : 'Communicating...'}}
      </p>
    </v-overlay>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'
import { mdiLogoutVariant } from '@mdi/js'

// Layouts
import useDynamicVh from '@core/utils/useDynamicVh'
import LayoutContent from '@/layouts/Content.vue'
import LayoutBlank from '@/layouts/Blank.vue'
import ConfirmationDialog from '@/components/elements/ConfirmationDialog.vue'
import Notify from '@/components/elements/Notify.vue'
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'

export default {
  components: {
    LayoutContent,
    LayoutBlank,
    ConfirmationDialog,
    Notify,
  },
  data() {
    return {
      icons: { mdiLogoutVariant },
    }
  },
  computed: {
    ...mapFields(['online', 'loading']),
    ...mapGetters('auth', ['user']),
    ...mapGetters('encounters', ['syncing', 'syncingPos', 'syncingCnt', 'syncingTxt']),
    progressValue() {
      return Math.max(Math.min(Math.round((this.syncingPos / this.syncingCnt) * 100), 100), 5)
    },
    progressValueSet() {
      return !Number.isNaN(this.progressValue)
    },
  },
  async mounted() {
    // Add an event listener to detect when the user goes offline
    window.addEventListener('load', () => {
      window.addEventListener('online', this.updateOnlineStatus)
      window.addEventListener('offline', this.updateOnlineStatus)
    })

    // Blocks closing tab, going back, or reloading browser if there's a filled out form.
    // Note: This isn't supported on iOS!
    window.addEventListener('beforeunload', e => {
      // Allow leaving from the login page without a warning
      if (this.$route.name !== 'login') {
        e.preventDefault()
        e.returnValue = ''
      }
    })

    // Set the current online status
    this.updateOnlineStatus()

    // Wait for auth to load
    this.$auth.load().finally(() => {
      this.loading = false
    })

    // Verify login credentials every minute
    setInterval(() => {
      const now = Math.floor(Date.now() / 1000)

      // console.log(`Verify Session: ${new Date().toTimeString().slice(0, 8)}`, now, this.$store.getters['auth/getSessionExpiration'], this.$store.getters['auth/getSessionExpiration'] - now)
      if (
        this.$authUser.user()
        && this.$store.getters['auth/getSessionExpiration'] > 0
        && now >= this.$store.getters['auth/getSessionExpiration']
      ) {
        // Session expired, logout
        this.$router.push({
          name: 'login',
          query: { notify: 'You\'ve been logged out due to inactivity.', color: 'error', timeout: -1 },
        }).catch(() => {})
      } else if (
        this.online
        && this.$authUser.user()
        && this.$store.getters['auth/getSessionExpiration'] > 0
        && this.$store.getters['auth/getSessionExpiration'] - now < 60 * 30 // 30 minute warning
      ) {
        // Session is about to expire, warn user
        this.$root.confirm({
          title: 'Stay Logged In?',
          body: 'Do you wish to stay logged in?',
          cancel: 'No, Logout',
          cancelColor: 'error',
          cancelIcon: this.icons.mdiLogoutVariant,
          confirm: 'Yes, Stay Logged In',
          logoutTimer: true,
        }).then(result => {
          if (result === true) {
            this.$store.dispatch('auth/refresh')
          } else {
            this.$store.dispatch('auth/logout')
          }
        })
      }
    }, 60 * 60 * 2 * 1000) // once every 2 hours

    // Look for new comments
    setInterval(() => {
      if (this.online && this.$authUser.user()) {
        this.$store.dispatch('comments/loadComments')
      }
    }, 15 * 60 * 1000) // Once every 15 minutes
  },
  updated() {
    // Set the confirm dialog on the $root so it can be called globally
    if (this.$refs.confirm?.open) this.$root.confirm = this.$refs.confirm.open
  },
  methods: {
    updateOnlineStatus() {
      this.online = window.navigator.onLine
    },
  },
  setup() {
    const { route } = useRouter()
    const { appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return 'layout-content'

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
}
</script>

<style lang="scss">
.loading-message {
  padding-top: 1rem;
}
.load-sync-progress {
  > svg {
    animation-name: progress-rotate;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .v-progress-circular__overlay {
    stroke: var(--v-primary-base);
  }
}
@keyframes progress-rotate {
    from {transform: rotate(180deg);}
    to {transform: rotate(540deg);}
}
.v-progress-circular--indeterminate .v-progress-circular__overlay {
  animation-duration: 2.4s;
  animation-timing-function: linear;
}
@keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 50, 200;
    stroke-dashoffset: 0px;
  }
  40% {
    stroke-dasharray: 110, 200;
    stroke-dashoffset: 0px;
  }
  70% {
    stroke-dasharray: 5, 200;
    stroke-dashoffset: -50px;
  }
  100% {
    stroke-dasharray: 50, 200;
    stroke-dashoffset: 0px;
  }
}
</style>
