<template>
  <div>
    <select-box
      v-model="selectedValue"
      :items="items"
      :label="label"
      :required="required"
      :suffix="suffix"
      :clearable="clearable"
      :disabled="disabled"
      :item-value="itemValue"
      :item-text="itemText"
      :rules="rules"
      :prepend-icon="prependIcon"
      :append-icon="appendIcon"
      :multiple="multiple"
      :dense="dense"
      :hide-details="hideDetails"
      :return-object="returnObject"
      :hint="hint"
      :single-line="singleLine"
      :label-top="labelTop"
      :readonly="readonly"
      :not-attached="notAttached"
      :no-outline="noOutline"
      :distinctive-none="distinctiveNone"
      @input="diagnosesSelectChanged"
      @click="$emit('click', $event)"
      @click:clear="$emit('click:clear', $event)"
      @click:append="$emit('click:append', $event)"
      @click:prepend="$emit('click:prepend', $event)"
    ></select-box>
    <text-field
      v-if="!otherTextArea && selectedIsOther"
      v-model="textFieldValue"
      :label="computedInputLabel"
      :rules="rules"
      :readonly="readonly"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :counter="counter"
      :disabled="disabled"
      :hide-details="hideDetails"
      :no-click="noClick"
      :prepend-icon="prependIcon"
      :not-clearable="notClearable"
      :label-top="labelTop"
      :suffix="suffix"
      :required="otherRequired"
      :hint="hint"
      :single-line="singleLine"
      :numeric="numeric"
      :email="email"
      :mask="mask"
      :append-outer-icon="appendOuterIcon"
      :prefix="prefix"
      :outlined="outlined"
      :readonly-validate="readonlyValidate"
      @blur="$emit('blur', $event)"
      @click="$emit('click', $event)"
      @input="$emit('input', $event)"
      @keyup.native="$emit('keyup', $event);"
      @click:prepend="$emit('click:prepend', true)"
    ></text-field>
    <text-area
      v-if="otherTextArea && selectedIsOther"
      v-model="textFieldValue"
      :label="computedInputLabel"
      :rules="rules"
      :readonly="readonly"
      :height="height"
      :rows="rows"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :counter="counter"
      :disabled="disabled"
      :hide-details="hideDetails"
      :clear-warning="clearWarning"
      :no-click="noClick"
      :auto-grow="autoGrow"
      :prepend-icon="prependIcon"
      :loading="loading"
      :wizard="wizard"
      :ai="ai"
      :paste="paste"
      :not-clearable="notClearable"
      :label-top="labelTop"
      :required="otherRequired"
      @blur="$emit('blur', $event)"
      @click="$emit('click', $event)"
      @input="$emit('input', $event)"
      @keyup.native="$emit('keyup', $event);"
      @click:prepend="$emit('click:prepend', true)"
    ></text-area>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { mdiMenuDown } from '@mdi/js'

export default {
  props: {
    value: {
      type: [String, Number, Boolean, Array, Object],
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    clearable: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemValue: {
      type: String,
      default: null,
    },
    itemText: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: String,
      default: mdiMenuDown,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    labelTop: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    notAttached: {
      type: Boolean,
      default: false,
    },
    noOutline: {
      type: Boolean,
      default: false,
    },
    distinctiveNone: {
      type: Boolean,
      default: false,
    },
    other: {
      type: String,
      default: 'Other',
    },
    otherTextArea: {
      type: Boolean,
      default: false,
    },

    // These are specific to the text-area component
    height: {
      type: [String, Number],
      default: null,
    },
    rows: {
      type: [String, Number],
      default: 3,
    },
    placeholder: {
      type: String,
      default: null,
    },
    maxlength: {
      type: String,
      default: null,
    },
    counter: {
      type: [String, Number],
      default: '255',
    },
    clearWarning: {
      type: Boolean,
      default: false,
    },
    noClick: {
      type: Boolean,
      default: false,
    },
    autoGrow: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    wizard: {
      type: Boolean,
      default: false,
    },
    ai: {
      type: Boolean,
      default: false,
    },
    paste: {
      type: Boolean,
      default: false,
    },
    notClearable: {
      type: Boolean,
      default: false,
    },
    otherRequired: {
      type: Boolean,
      default: false,
    },
    otherLabel: {
      type: String,
      default: null,
    },

    // These are specific to the text-field component
    numeric: {
      type: Boolean,
      default: false,
    },
    email: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: [String, Array, Function, Boolean],
      default: '',
    },
    appendOuterIcon: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    readonlyValidate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      textFieldValue: this.value,
      selectedValue: null,
      icons: { mdiMenuDown },
    }
  },
  computed: {
    valueOnMenu() {
      return this.items.some(x => x.title === this.selectedValue)
    },
    selectedIsEmpty() {
      return this.$custom.isEmpty(this.selectedValue)
    },
    selectedIsOther() {
      return this.selectedValue === this.other || (!this.valueOnMenu && !this.selectedIsEmpty)
    },
    computedInputLabel() {
      return this.otherLabel ? this.otherLabel : this.label
    },
  },
  watch: {
    value() {
      this.textFieldValue = this.value
    },
  },
  mounted() {
    this.selectedValue = this.textFieldValue
    if (this.selectedIsOther) this.selectedValue = this.other
  },
  methods: {
    diagnosesSelectChanged() {
      if (this.selectedIsEmpty || this.selectedIsOther) {
        this.textFieldValue = null
      } else if (this.valueOnMenu) {
        this.textFieldValue = this.selectedValue
      }
      this.$emit('change', this.textFieldValue)
      this.$emit('input', this.textFieldValue)
    },
  },
}
</script>
