<template>
  <v-alert
    :color="color"
    :icon="computedIcon"
    text
    outlined
    border="left"
  >
    <slot>{{ value }}</slot>
  </v-alert>
</template>

<script>
import {
  mdiAlert, mdiAlertOctagon, mdiThumbUp, mdiInformation,
} from '@mdi/js'

export default {
  props: {
    color: {
      type: String,
      default: 'warning',
    },
  },
  data() {
    return {
      icons: {
        mdiAlert, mdiAlertOctagon, mdiThumbUp, mdiInformation,
      },
    }
  },
  computed: {
    computedIcon() {
      switch (this.color) {
        case 'error': return this.icons.mdiAlertOctagon
        case 'warning': return this.icons.mdiAlert
        case 'success': return this.icons.mdiThumbUp
        default: return this.icons.mdiInformation
      }
    },
  },
}
</script>
