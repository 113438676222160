import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: { name: 'home' } },
  { path: '/notes', redirect: { name: 'home' } },

  /* Home Page */
  {
    path: '/notes',
    name: 'home',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/Home.vue'),
  },

  /* Places of service */
  {
    path: '/notes/facilities',
    name: 'facilities',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/PlacesOfService.vue'),
  },

  /* Facility Playlist */
  {
    path: '/notes/facilities-playlist',
    name: 'facilities-playlist',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/FacilityPlaylist.vue'),
  },

  /* Comments */
  {
    path: '/notes/comments',
    name: 'list-comments',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/Comments.vue'),
  },

  /* Patients */
  {
    path: '/notes/patients',
    name: 'list-patients',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/patients/List.vue'),
  },
  {
    path: '/notes/patients/find',
    name: 'find-patient',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/patients/Find.vue'),
  },
  {
    path: '/notes/patients/add',
    name: 'add-patient',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/patients/Add.vue'),
  },
  {
    path: '/notes/patients/edit',
    name: 'edit-patient',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/patients/Edit.vue'),
  },
  {
    path: '/notes/patients/view',
    name: 'view-patient',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/patients/View.vue'),
  },
  {
    path: '/notes/patient/dump',
    name: 'dump-patient',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/patients/Dump.vue'),
  },

  /* Encounters */
  {
    path: '/notes/encounters',
    name: 'list-encounters',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/encounters/List.vue'),
  },
  {
    path: '/notes/encounters/patient-find',
    name: 'encounter-patient-find',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/encounters/PatientFind.vue'),
  },
  {
    path: '/notes/encounters/create',
    name: 'create-encounter',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/encounters/Create.vue'),
  },
  {
    path: '/notes/encounter/dump',
    name: 'dump-encounter',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/encounters/Dump.vue'),
  },

  /* Quick Entry */
  {
    path: '/notes/quick-entry',
    name: 'quick-entry',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/quick-entry/QuickEntry.vue'),
  },
  {
    path: '/notes/quick-entry/patient-find',
    name: 'quick-entry-patient-find',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/quick-entry/PatientFind.vue'),
  },
  {
    path: '/notes/quick-notes',
    name: 'quick-notes',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/quick-entry/List.vue'),
  },

  /* Encounter Detail */
  {
    path: '/notes/encounter-detail/wound-care',
    name: 'encounter-wound-care',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/encounter-detail/WoundCare.vue'),
  },
  {
    path: '/notes/encounter-detail/psychology',
    name: 'encounter-psychology',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/encounter-detail/Psychology.vue'),
  },
  {
    path: '/notes/encounter-detail/primary-care',
    name: 'encounter-primary-care',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/encounter-detail/PrimaryCare.vue'),
  },
  {
    path: '/notes/encounter-detail/podiatry',
    name: 'encounter-podiatry',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/encounter-detail/Podiatry.vue'),
  },
  {
    path: '/notes/encounter-detail/psychiatry',
    name: 'encounter-psychiatry',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/encounter-detail/Psychiatry.vue'),
  },

  /* Encounter Notes */
  {
    path: '/notes/encounter-note/wound-care',
    name: 'encounter-note-wound-care',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/encounter-note/WoundCare.vue'),
  },
  {
    path: '/notes/encounter-note/psychology',
    name: 'encounter-note-psychology',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/encounter-note/Psychology.vue'),
  },
  {
    path: '/notes/encounter-note/primary-care',
    name: 'encounter-note-primary-care',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/encounter-note/PrimaryCare.vue'),
  },
  {
    path: '/notes/encounter-note/podiatry',
    name: 'encounter-note-podiatry',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/encounter-note/Podiatry.vue'),
  },
  {
    path: '/notes/encounter-note/psychiatry',
    name: 'encounter-note-psychiatry',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/encounter-note/Psychiatry.vue'),
  },

  /* General */
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'blank' },
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '*',
    name: 'page-not-found',
    meta: { layout: 'blank' },
    component: () => import('@/views/Error404.vue'),
  },

  /*
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: { layout: 'blank' },
    component: () => import('@/views/ForgotPassword.vue'),
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta: { layout: 'blank' },
    component: () => import('@/views/ResetPassword.vue'),
  },
  */

  /* Debug Page */
  {
    path: '/debugPage',
    name: 'debugPage',
    meta: { layout: 'content', auth: true },
    component: () => import('@/views/notes/dev-page/DebugPage.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

const waitForStorageToBeReady = async (to, from, next) => {
  Vue.store.commit('route/addRoute', to)
  await store.restored
  next()
}

// Since localForage is async, we must wait for the storage to be ready before loading the route
router.beforeEach(waitForStorageToBeReady)

// On each new route, verify login credentials haven't expired
router.beforeResolve((to, from, next) => {
  const now = Math.floor(Date.now() / 1000)

  // console.log(`Verify Session: ${new Date().toTimeString().slice(0, 8)}`, now, Vue.store.getters['auth/getSessionExpiration'], Vue.store.getters['auth/getSessionExpiration'] - now)
  if (
    Vue.auth.user()
    && from.name !== 'login'
    && to.name !== 'login'
    && Vue.store.getters['auth/getSessionExpiration'] > 0
    && now >= Vue.store.getters['auth/getSessionExpiration']
  ) {
    Vue.router.push({
      name: 'login',
      query: { notify: 'You\'ve been logged out due to inactivity.', color: 'error', timeout: -1 },
    }).catch(() => {})
  } else {
    next()
  }
})

Vue.router = router

export default router
