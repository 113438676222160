<template>
  <v-row
    class="pagination justify-space-between mt-5 mx-4 mb-1"
  >
    <select-box
      v-model="paginationItemsPerPage"
      :items="itemsPerPageList"
      label="Items per page"
      class="col-sm-auto mt-1 ml-1"
      clearable="false"
      :disabled="disabled"
    >
    </select-box>
    <v-pagination
      v-model="paginationPage"
      :length="pageCount"
      total-visible="7"
      class="col-sm-auto py-0 pr-0"
      :disabled="disabled"
    ></v-pagination>
  </v-row>
</template>

<script>
export default {
  props: {
    page: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemsPerPageList: {
      type: Array,
      default: () => [
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '15', value: 15 },
        { label: '25', value: 25 },
        { label: '100', value: 100 },
        { label: 'All', value: -1 },
      ],
    },
  },
  data() {
    return {
      paginationPage: this.page,
      paginationItemsPerPage: this.itemsPerPage,

      // itemsPerPageList: [
      //   { label: '5', value: 5 },
      //   { label: '10', value: 10 },
      //   { label: '15', value: 15 },
      //   { label: '25', value: 25 },
      //   { label: '100', value: 100 },
      //   { label: 'All', value: -1 },
      // ],
    }
  },
  watch: {
    page() {
      this.paginationPage = this.page
    },
    itemsPerPage() {
      this.paginationItemsPerPage = this.itemsPerPage
    },
    paginationPage() {
      this.$emit('update:page', this.paginationPage)
    },
    paginationItemsPerPage() {
      this.paginationPage = 1
      this.$emit('update:items-per-page', this.paginationItemsPerPage)
    },
  },
  mounted() {
    // Emit the default values in case they were not defined
    this.$emit('update:page', this.paginationPage)
    this.$emit('update:items-per-page', this.paginationItemsPerPage)
  },
}
</script>
