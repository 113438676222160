<template>
  <v-card
    outlined
    class="note-section px-5 pb-2 mx-2 my-5"
    :class="`${heading ? 'heading' : ''} ${minimized ? 'minimized' : ''} ${accordion ? 'has-accordion' : ''} ${customClass ? customClass : 'full-width'}`"
    :tile="heading"
  >
    <v-card-title>
      <label>{{ computedLabel }}</label>
      <div
        v-if="accordion"
        class="accordion-section-icon"
      >
        <v-icon
          color="primary"
          @click="minimizeToggle"
        >
          {{ icons.mdiChevronUp }}
        </v-icon>
      </div>
    </v-card-title>
    <v-row class="align-start">
      <slot></slot>
    </v-row>
  </v-card>
</template>

<script>
import { mdiChevronUp } from '@mdi/js'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    heading: {
      type: Boolean,
      default: false,
    },
    accordion: {
      type: Boolean,
      default: false,
    },
    minimize: {
      type: Boolean,
      default: false,
    },
    minimizedLabel: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      minimized: this.minimize,
      icons: { mdiChevronUp },
    }
  },
  computed: {
    computedLabel() {
      return this.$custom.toTitleCase(this.minimized && this.minimizedLabel ? this.minimizedLabel : this.label)
    },
  },
  methods: {
    minimizeToggle() {
      this.minimized = !this.minimized
    },
  },
}
</script>

<style lang="scss">
.note-section {
  &.full-width {
    flex-basis: 100%;
  }
  max-width: unset;
  background-color: rgb(251, 251, 251) !important;
  > .v-card__title {
    display: block;
    text-align: center;
    padding: 0 !important;
    margin-top: 0;
    margin-bottom: 20px;
    label {
      font-size: 16px;
      font-weight: 600;
      color: var(--v-secondary-darken3) !important;
      line-height: 2;
      padding: 0;
    }
    .accordion-section-icon {
      position: absolute;
      z-index: 2;
      top: -4px;
      right: 4px;
      padding: 4px !important;
      width: 44px;
      height: 44px;
      .v-icon__svg {
        width: 36px;
        height: 36px;
        transition: transform 0.2s ease-out;
      }
    }
  }
  &.heading {
    background-color: var(--v-secondary-darken2) !important;
    height: 36px;
    padding-bottom: 0 !important;
    border-width: 0;
    > .v-card__title {
      height: 36px;
      label {
        font-size: 18px;
        line-height: 36px;
        color: map-get($shades, 'white') !important;
      }
    }
  }
  > div.row {
    align-items: center;
    > *:not(.v-alert) {
      padding: 0 4px;
      font-size: 0.875rem;
      &.v-btn {
        margin-bottom: 25px;
      }
    }
    > .v-card__text, > .v-card__actions {
      margin-bottom: 20px;
    }
    .v-card__actions {
      margin-top: 8px;
    }
    > .v-alert {
      flex: 1 1 100%;
      margin-left: 4px;
      margin-right: 4px;
    }
  }
  &.minimized {
    > .v-card__title {
      .accordion-section-icon {
        .v-icon__svg {
          transform: rotate(-180deg);
        }
      }
    }
    > div.row {
      max-height: 0;
      overflow: hidden;
    }
  }
}
</style>
