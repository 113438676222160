import Alert from '@/components/elements/Alert.vue'
import Btn from '@/components/elements/Btn.vue'
import Checkbox from '@/components/elements/Checkbox.vue'
import CheckBoxes from '@/components/elements/CheckBoxes.vue'
import Chip from '@/components/elements/Chip.vue'
import DateInput from '@/components/elements/DateInput.vue'
import DatePicker from '@/components/elements/DatePicker.vue'
import HelpIcon from '@/components/elements/HelpIcon.vue'
import IconValue from '@/components/elements/IconValue.vue'
import NoteSection from '@/components/elements/NoteSection.vue'
import Pagination from '@/components/elements/Pagination.vue'
import RadioButtons from '@/components/elements/RadioButtons.vue'
import SelectBox from '@/components/elements/SelectBox.vue'
import SelectBoxOtherInput from '@/components/elements/SelectBoxOtherInput.vue'
import ShowValue from '@/components/elements/ShowValue.vue'
import TextArea from '@/components/elements/TextArea.vue'
import TextField from '@/components/elements/TextField.vue'
import Vue from 'vue'

Vue.component('alert', Alert)
Vue.component('btn', Btn)
Vue.component('checkbox', Checkbox)
Vue.component('check-boxes', CheckBoxes)
Vue.component('chip', Chip)
Vue.component('date-picker', DatePicker)
Vue.component('date-input', DateInput)
Vue.component('help-icon', HelpIcon)
Vue.component('icon-value', IconValue)
Vue.component('NoteSection', NoteSection)
Vue.component('radio-buttons', RadioButtons)
Vue.component('select-box', SelectBox)
Vue.component('select-box-other-input', SelectBoxOtherInput)
Vue.component('show-value', ShowValue)
Vue.component('text-field', TextField)
Vue.component('text-area', TextArea)
Vue.component('pagination', Pagination)
