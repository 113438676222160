/* eslint-disable no-unused-vars */
import cloneDeep from 'lodash/cloneDeep'
import { v4 as uuidv4 } from 'uuid'
import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'

const initialState = {
  batchSize: 10,
  error: false,
  items: [],
  apiDetails: {},
  isSynced: false,
}

function isObject(candidate) {
  return (typeof candidate === 'object' || typeof candidate === 'function') && (candidate !== null)
}

function removeJoinData(object) {
  const filtered = { ...object }
  Object.keys(filtered).forEach(key => {
    if (key === '_joinData') {
      delete filtered[key]
    } else if (isObject(filtered[key])) {
      filtered[key] = removeJoinData(filtered[key])
    }
  })

  return filtered
}

// Function to extract treatments and calculate object size
function extractTreatmentsAndSize(patientData) {
  const treatments = []
  let objectSize = 0

  // Iterate through patientData and extract treatments
  patientData.forEach(patient => {
    if (patient.hasOwnProperty('wounds')) {
      patient.wounds.forEach(wound => {
        if (wound.hasOwnProperty('treatments')) {
          treatments.push(...wound.treatments)
        }
      })
    }
  })

  // Calculate object size
  const jsonString = JSON.stringify(treatments)
  objectSize = new Blob([jsonString]).size

  // Convert object size to megabytes
  const objectSizeMB = (objectSize / (1024 * 1024)).toFixed(2)

  // Return treatments array and object size in MB
  return { treatmentsCount: treatments.length, objectSizeMB }
}

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  getters: {
    getField,
    getLength: state => state.items.length,
    getLastId: state => (state.items.length > 0 ? state.items[state.items.length - 1].id : false),
    getDuplicates: state => newPatient => state.items.filter(patient => {
      if (
        patient.dob === newPatient.dob
          && patient.first_name.toLowerCase() === newPatient.first_name.trim().toLowerCase()
          && patient.last_name.toLowerCase() === newPatient.last_name.trim().toLowerCase()
          && ((newPatient.ssn_last_4 && newPatient.ssn_last_4.length === 4 && patient.ssn_last_4 === newPatient.ssn_last_4)
            || !newPatient.ssn_last_4)
          && ((newPatient.id && patient.id !== newPatient.id) || !newPatient.id)
      ) return true

      return false
    }),
    getById(state) {
      return (id, idField = 'id') => state.items.find(item => item[idField] === id) || {}
    },
    getIndexById: state => id => state.items.findIndex(x => x.id === id),
    patientsToSync: state => state.items.filter(patient => (patient.updated)),
  },
  mutations: {
    DEBUG__deletePatients: state => {
      const localPatients = state.items.filter(patient => (
        patient.updated
      ))

      const newStateItems = state.items
      for (let i = localPatients.length - 1; i >= 0; i--) {
        state.items.splice(newStateItems.findIndex(index => index.id === localPatients[i].id), 1)
      }
    },

    updateField,
    addPatient: (state, value) => {
      const newValue = {
        ...value,
        updated: true,
      }
      if (!newValue.id) newValue.id = uuidv4()
      state.items.push(newValue)
    },
    addPatients: (state, value) => {
      value.forEach(note => {
        state.items.push(note)
      })
    },
    updatePatient: (state, data) => {
      let patientArr = []
      if (Array.isArray(data)) {
        patientArr = data
      } else {
        patientArr.push(data)
      }

      patientArr.forEach(patient => {
        // Find the patient index
        const index = state.items.findIndex(x => x.id === patient.id)

        // Vue.prototype.$custom.freezeAttachmentsAndTreatments(patient)

        // Update patient values
        state.items[index] = { ...patient, updated: true }
      })
    },
    updateAttachments: (state, { id = false, attachments = [] }) => {
      // Find the patient index
      const index = state.items.findIndex(x => x.id === id)

      // Update patient attachments
      if (index !== -1) {
        state.items[index].all_attachments = [...attachments]
        state.items[index].updated = true

        // Set synced status to false. Note that this is the general sync state and not the is_sync flag for encounters.
        Vue.store.commit('encounters/synced', false)
      }
    },
    updatePatientSync: (state, data) => {
      let patientArr = []
      if (Array.isArray(data)) {
        patientArr = data
      } else {
        patientArr.push(data)
      }
      patientArr.forEach(patient => {
      // Find the patient index

        const index = state.items.findIndex(x => x.id === patient.id)

        // Update patient values
        state.items[index] = { ...patient }
      })
    },
    deletePatient: (state, id) => {
      // Find the patient index
      const index = state.items.findIndex(x => x.id === id)

      // Delete patient only if there's no wound locations
      if (index !== -1 && (!state.items[index].wounds || state.items[index].wounds.length === 0)) {
        state.items.splice(index, 1)
      }

      state.isSynced = false
    },
    updateFacilityAcquiredWound: (state, value) => {
      // Find the patient index
      const index = state.items.findIndex(x => x.id === value.id)
      if (index !== -1) {
        // Iterate through wounds
        // console.log(state.items[index].wounds)
        state.items[index].wounds.forEach((item, i) => {
          // If the wound's facility ID does not match the new facility, mark wounds as not facility acquired
          // console.log(item, i)
          if (item.wound_acquired_facility_id && item.wound_acquired_facility_id !== value.facility) {
            state.items[index].wounds[i].is_wound_facility_acquired = false
            state.items[index].wounds[i].is_wound_not_recent = false
            state.items[index].wounds[i].wound_acquired_facility_id = null
            state.items[index].wounds[i].updated = true
          }
        })

        // Flag patient as updated
        state.items[index].updated = true
      }

      // Set synced status to false
      Vue.store.commit('encounters/synced', false)
    },
    RESET_STATE: state => {
      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })
    },
    SET_PATIENTS(state, data) {
      Vue.set(state, 'items', data)
    },
    SET_SYNCING: (state, value) => {
      state.syncing = value
    },
    SET_API_DETAILS: (state, data) => {
      state.apiDetails = data
      console.log(`Total patients synced: ${data.items}`)
      console.log(`Query: ${data.query_time}s, Optimize: ${data.optimize_time}s`)
    },
    SET_SYNCED: (state, value) => {
      state.isSynced = value
    },
  },
  actions: {
    syncPatient({ commit }, data) {
      const patientsToSync = []
      let patientArr = []
      if (Array.isArray(data)) {
        patientArr = data
      } else {
        patientArr.push(data)
      }
      let corePatient
      patientArr.forEach(patient => {
        corePatient = cloneDeep(patient)

        delete corePatient.all_attachments
        delete corePatient.wounds
        delete corePatient.dob_us
        delete corePatient.updated
        delete corePatient.updated_core

        patientsToSync.push(corePatient)
      })

      return Vue.axios.post('patients.json', patientsToSync)
        .then(response => response.data)
        .catch(e => Vue.prototype.$custom.processCommError(e, 'Upload core patient'))
    },
    syncFullPatient({ commit }, data) {
      const patientsToSync = []
      let patientArr = []
      if (Array.isArray(data)) {
        patientArr = cloneDeep(data) // Create a deep copy of the patient array to avoid modifying the original data
      } else {
        patientArr.push(cloneDeep(data)) // Create a deep copy of the patient array to avoid modifying the original data
      }
      patientArr.forEach(patient => {
        // Check if patient.wounds exists
        if (patient.wounds) {
          // Only sync wounds that were updated.
          patient.wounds = patient.wounds.filter(wound => wound.updated)

          // Check if wounds have treatments and only sync treatments that were updated.
          patient.wounds.forEach(wound => {
            if (wound.treatments) {
              wound.treatments = wound.treatments.filter(treatment => treatment.updated)
            }
          })
        }
        // Only include attachments that are not yet synced(updated = true).
        if (patient.all_attachments) {
          patient.all_attachments = patient.all_attachments.filter(attachment => attachment.updated)
        }
        // Remove all '_joinData' objects
        const processedPatient = removeJoinData(patient)

        patientsToSync.push(processedPatient)
      })

      return Vue.axios.post('patients.json', patientsToSync)
        .then(response => response.data.responseData)
        .catch(e => Vue.prototype.$custom.processCommError(e, 'Error on Upload Full Patient'))
    },
    getPatientAttachment({ commit }, { patientId, attachmentId }) {
      return Vue.axios.get(`/patients/attachments.json?patientId=${patientId}&attachmentId=${attachmentId}`)
        .then(response => response.data)
        .catch(e => Vue.prototype.$custom.processCommError(e, 'Unable to download patient attachments'))
    },
    loadPatients({ commit }) {
      console.log('Getting patients...')

      let items = 0
      let queryTime = 0
      let optimizeTime = 0
      let patientData = []
      commit('encounters/SET_SYNCING_TXT', `Fetching patient data`, { root: true})
      const fetchPage = page => Vue.axios.get(`patients.json?page=${page}`)
        .then(response => {
          if (response.data.status === 'Success') {
            const { totalPages } = response.data
            commit('encounters/SET_SYNCING_TXT', `Fetching patient data: page ${page+1} of ${totalPages}`, { root: true})
            console.log(`GET patients progress: page ${page} of ${totalPages}`)

            patientData.push(...response.data.data)
            items += response.data.details.items
            queryTime += parseFloat(response.data.details.query_time)
            optimizeTime += parseFloat(response.data.details.optimize_time)

            // If there are more pages, fetch the next page recursively
            if (response.data.hasMorePages) {
              // Clear references to release memory.
              response = null

              return fetchPage(page + 1)
            }

            // Extract treatments and calculate object size
            // const { treatmentsCount, objectSizeMB } = extractTreatmentsAndSize(patientData);
            // console.log('Treatments Count:', treatmentsCount);
            // console.log('Treatments Size:', `${objectSizeMB} MB`);

            // Freeze attachments and treatments from signed patients
            patientData.forEach(Vue.prototype.$custom.freezeAttachmentsAndTreatments)

            const updatedResponseData = {
              ...response.data,
              data: patientData,
              details: {
                ...response.data.details,
                items,
                query_time: queryTime.toFixed(3),
                optimize_time: optimizeTime.toFixed(3),
              },
            }

            // *** For testing only ***
            // const jsonString = JSON.stringify(updatedResponseData);
            // const objSizeBytes = new Blob([jsonString]).size;
            // const objSizeMB = (objSizeBytes / (1024 * 1024)).toFixed(2);
            // console.log(`Total object size: ${objSizeMB } MB`);

            // Define the threshold for the object size in megabytes
            // const objectSizeThreshold = 70;
            // // Check if the object size exceeds the specified threshold
            // if (objSizeMB > objectSizeThreshold) {
            //   // If the size exceeds the threshold, create an error message
            //   const errorMessage = `Unable to sync patient records! The size of the data exceeds the limit allowed for the device (${objectSizeThreshold}MB). Please contact Skilled Wound Care to be assigned a Windows device.`;
            //
            //   // Return the error message to indicate failure
            //   return errorMessage;
            // }

            // Clear references to release memory.
            patientData = null
            items = null
            queryTime = null
            optimizeTime = null

            // Commit the updated patients and API details
            commit('SET_PATIENTS', updatedResponseData.data)
            commit('SET_API_DETAILS', Vue.prototype.$custom.deepFreeze(updatedResponseData.details))

            return true
          }

          return response.data.message ? response.data.message : 'Download patients - Unknown error'
        })
        .catch(e => Vue.prototype.$custom.processCommError(e, 'Download patients'))

      return fetchPage(1) // Start fetching from page 1
    },
  },
}
