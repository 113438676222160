<template>
  <text-field
    v-model="inputValue"
    :suffix="computedSuffix"
    :label="label"
    :mask="dateMask"
    :rules="computedRules"
    :append-icon="appendIcon"
    :readonly="readonly"
    :not-clearable="notClearable"
    :disabled="disabled"
    :hide-details="hideDetails"
    :hint="hint"
    :outlined="outlined"
    :single-line="singleLine"
    :label-top="labelTop"
    :date-input="dateInput"
    placeholder="MM/DD/YYYY"
    maxlength="10"
    numeric
    :required="required"
    @blur="$emit('blur', $event)"
    @click="$emit('click', $event)"
    @input="$emit('input', inputValue)"
    @click:clear="$emit('input', null); $emit('click:clear', null)"
    @click:append="$emit('click:append', $event)"
    @focus="$emit('focus', $event)"
    @keyup.enter="$emit('blur', $event)"
    @keyup="keyPress($event)"
  ></text-field>
</template>

<script>
/* eslint-disable no-eval */
import { mdiClose } from '@mdi/js'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Boolean],
      default: 'date',
    },
    max: {
      type: String,
      default: null,
    },
    min: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: [String, Boolean],
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    notClearable: {
      type: Boolean,
      default: false,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    labelTop: {
      type: Boolean,
      default: false,
    },
    showAge: {
      type: Boolean,
      default: false,
    },
    dateInput: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      inputValue: this.value,
      icons: { mdiClose },
      dateMask: [/[0-9]/, /[0-9|/|-]/, /[0-9|/|-]/, /[0-9]/, /[0-9|/|-]/, /[0-9|/|-]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    }
  },
  computed: {
    computedSuffix() {
      if (!this.showAge) return this.suffix
      return this.$custom.getAgeDescription(this.inputValue)
    },
    computedRules() {
      return !this.required && this.$custom.isEmpty(this.inputValue) ? null : this.rules
    },
  },
  watch: {
    value() {
      this.inputValue = this.value
    },
  },
  methods: {
    keyPress(event) {
      if (this.inputValue && event.key !== 'Backspace') {
        if (event.key === '/' || event.key === '-') {
          const newDate = this.inputValue.replaceAll('-', '/').split('/')
          newDate.forEach((section, index, array) => {
            if (index < 2 && section.length && index + 1 < array.length) {
              newDate[index] = section.padStart(2, '0')
            }
          })
          if (newDate.join('/') !== this.inputValue) this.inputValue = newDate.join('/')
        } else if (this.inputValue.length === 2 || this.inputValue.length === 5) {
          this.inputValue += '/'
        } else if ((this.inputValue.length === 3 || this.inputValue.length === 6) && this.inputValue.slice(-1) !== '/') {
          this.inputValue = `${this.inputValue.substr(0, this.inputValue.length - 1)}/${this.inputValue.slice(-1)}`
        }
      }
      this.$emit('input', this.inputValue)
    },
  },
}
</script>
