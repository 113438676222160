<template>
  <div
    :class="`checkbox-container ${noteRequiredField ? 'required-field' : ''} ${computedColumns ? 'columns-' + computedColumns : ''}`"
    style="width: 100%; position: relative"
  >
    <label
      :class="`${label ? '' : 'empty-label'} ${labelTitle ? 'label-title' : ''}`"
    >
      <strong
        v-if="noteRequiredField"
        class="error--text"
      >*</strong>
      {{ label }}
    </label>
    <v-row
      class="checkbox-group"
      :class="columnSort ? 'flex-column' : ''"
      :style="columnSort ? `max-height: ${computedFieldHeight}px` : ''"
    >
      <v-checkbox
        v-for="(item, i) in items"
        :key="`item-${i}`"
        v-model="selectedValues"
        :label="item[computedLabel]"
        :value="returnObject ? item : item[computedValue]"
        :rules="computedRules"
        :disabled="disabled"
        :hide-details="hideDetails"
        :off-icon="radioButtons ? '$radioOff' : '$checkboxOff'"
        :on-icon="radioButtons ? '$radioOn' : '$checkboxOn'"
        :append-icon="i === items.length - 1 ? computedAppendIcon : null"
        :class="(noteRequiredField && label === '' && !computedColumns) ? 'required-checkbox' : ''"
        :ripple="false"
        @change="emitChanges($event)"
        @click="itemClicked($event)"
        @click:append="clickAppendIcon($event)"
      ></v-checkbox>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-lonely-if */
export default {
  props: {
    value: {
      type: Array,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    columnSort: {
      type: Boolean,
      default: false,
    },
    column: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: String,
      default: null,
    },
    itemValue: {
      type: String,
      default: null,
    },
    itemLabel: {
      type: String,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    labelTitle: {
      type: Boolean,
      default: false,
    },
    distinct: {
      type: [Array, String],
      default: null,
    },
    radioButtons: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedValues: [...this.sanitizedValues()],
      index: 0,
    }
  },
  computed: {
    noteRequiredField() {
      return !this.disabled && this.required && this.selectedValues.length === 0
    },
    computedRules() {
      return (this.required && !this.disabled) ? this.$validationRules.requiredCheckboxes : []
    },
    computedColumns() {
      if (this.column || this.columns !== null) {
        return this.columns ? this.columns : 1
      }

      return false
    },
    computedValue() {
      if (this.itemValue) return this.itemValue
      if (this.items && this.items.length) {
        if (typeof this.items[0].id !== 'undefined') return 'id'
        if (typeof this.items[0].value !== 'undefined') return 'value'
        if (typeof this.items[0].title !== 'undefined') return 'title'
        if (typeof this.items[0].label !== 'undefined') return 'label'
      }

      return 'value'
    },
    computedLabel() {
      if (this.itemLabel) return this.itemLabel
      if (this.items && this.items.length) {
        if (typeof this.items[0].title !== 'undefined') return 'title'
      }

      return 'label'
    },
    computedFieldHeight() {
      return Math.ceil(this.items.length / this.computedColumns) * (this.hideDetails ? 49 : 51)
    },
    computedAppendIcon() {
      if (this.appendIcon) return this.appendIcon
      if (this.clearable) {
        return this.selectedValues.length ? '$close' : null
      }

      return null
    },
  },
  watch: {
    value() {
      this.selectedValues = [...this.sanitizedValues()]
    },
  },
  methods: {
    emitChanges(values) {
      this.$emit('change', values)
      this.$emit('input', values)
    },
    getCheckbox(event) {
      // With a checkbox, you can click on either the box or the label, so this nonsense is required if the user clicks the label, or outside the box or label
      switch (event.target?.tagName) {
        case 'INPUT': return event.target
        case 'LABEL': return event.target?.previousElementSibling?.querySelector('input')
        default: return event.target?.querySelector('input')
      }
    },
    itemClicked(event) {
      if (this.radioButtons) {
        // nextTick to make sure the click has finished changing the selected values
        this.$nextTick(() => {
          this.emitChanges([this.getCheckbox(event)?._value])
        })
      } else if (this.distinct) {
        // nextTick to make sure the click has finished changing the selected values
        this.$nextTick(() => {
          const checkbox = this.getCheckbox(event)
          const title = checkbox?._value?.title

          const newSelectedValues = this.distinct.includes(title)
            ? this.selectedValues.filter(x => this.distinct.includes(x.title))
            : this.selectedValues.filter(x => !this.distinct.includes(x.title))

          // Only emit and notify the user if there were actually changes
          if (this.$custom.notEmpty(this.$lodash.xor(this.selectedValues, newSelectedValues))) {
            this.emitChanges(newSelectedValues)
            const distinctList = this.$custom.toList(this.distinct, null, '&', 'none').trim()
            const isAre = (this.$custom.isArray(this.distinct) && this.distinct.length > 1) ? 'are' : 'is'
            this.$store.dispatch('notify', { value: `Option(s) unselected as '${distinctList}' ${isAre} exclusive.`, color: 'warning' })
          }
        })
      }
      this.$emit('click', event)
    },
    sanitizedValues() {
      if (this.value.length && this.value[0]?._joinData) {
        return this.value.map(item => ({ id: item.id, title: item.title }))
      }

      return this.value
    },
    clickAppendIcon(event) {
      if (this.clearable) this.emitChanges([])
      this.$emit('click:append', event)
    },
  },
}
</script>

<style lang="scss">
.required-field .required-checkbox:first-child::before {
  content: '*';
  color: var(--v-error-base);
  font-weight: 700;
  position: relative;
  top: 14px;
  height: 15px;
}
.checkbox-container .checkbox-group > .v-input--checkbox:first-child .v-messages .v-messages__wrapper {
  position: absolute;
  min-width: 500px;
}
</style>
