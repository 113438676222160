<template>
  <layout-content
    :nav-menu-items="navMenuItems"
    :update.sync="update"
  >
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div class="navbar-content-container">
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
        </div>
        <template
          v-if="isDevMode()"
          class="d-flex align-center right-row"
        >
          <img
            src="@/assets/images/logos/blackWhiteCat.png"
            style="width: 50px; height: 50px;"
            alt="Cute Kitten"
          >
          SWC DEVELOPMENT SERVER
        </template>
        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span>&copy; {{ new Date().getFullYear() }} {{ appName }}<span class="d-none d-md-inline">, All rights Reserved</span></span>
        <span>v{{ appVersion }}</span>
      </div>
    </template>
  </layout-content>
</template>

<script>
import { mdiMenu } from '@mdi/js'
import themeConfig from '@themeConfig'
import LayoutContent from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import navMenuItems from '@/navigation/default'
import { mapState } from 'vuex'

// App Bar Components
import AppBarUserMenu from '@/components/AppBarUserMenu.vue'

export default {
  components: {
    LayoutContent,

    // App Bar Components
    AppBarUserMenu,
  },
  setup() {
    return {
      update: false,
      navMenuItems,
      icons: {
        mdiMenu,
      },
      appName: themeConfig.app.name,
      appVersion: themeConfig.app.version,
    }
  },
  computed: {
    ...mapState('comments', { comments: 'items' }),
    commentMenuIndex() {
      return this.navMenuItems.findIndex(x => x.to === 'list-comments')
    },
  },
  watch: {
    comments: {
      deep: true,
      handler() {
        this.navMenuItems[this.commentMenuIndex].badge = this.comments.filter(x => !x.is_read).length
        this.update = true
      },
    },
  },
  mounted() {
    this.$store.dispatch('encounters/syncingModal', false)
    this.$store.dispatch('loading', false)
    this.navMenuItems[this.commentMenuIndex].badge = this.comments.filter(x => !x.is_read).length
    this.update = true
  },
  methods: {
    isDevMode() {
      return process.env.NODE_ENV === 'development'
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
</style>
