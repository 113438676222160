<template>
  <div :class="`icon-value ${color}--text`">
    <v-icon
      left
      :color="color"
      :disabled="disabled"
    >
      {{ icon }}
    </v-icon>
    <slot>
      {{ value }}
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'accent-lighten5',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
