import Vue from 'vue'

export default {
  request(req, token) {
    if (req.url === this.options.refreshData.url) {
      // Use Refresh token for refresh requests
      // eslint-disable-next-line no-param-reassign
      token = this.token(this.options.refreshTokenName)
      // eslint-disable-next-line no-param-reassign
      req.data = { refresh_token: token }
    }

    let accept = 'application/json'
    let contentType = 'application/json'

    // Allow override of header
    if (req.transformHeader) {
      accept = req.transformHeader
      contentType = req.transformHeader
    }

    this.drivers.http.setHeaders.call(this, req, {
      'Content-Type': contentType,
      Accept: accept,
      Authorization: token,
    })
  },
  response(res) {
    const data = res.data || {}

    if (data.refresh) {
      this.token(this.options.refreshTokenName, data.refresh)
    }

    if (res.config.url === this.options.fetchData.url) {
      /**
       * This is a auth.fetch() (get user) response
       * This runs on page load, right after initial authentication (including page reloads)
       */
      // Set user data from response
      Vue.store.commit('auth/SET_USER_LOGIN', data.data || {})

      // Check if syncing flag is false before dispatching loadData
      const isSyncing = Vue.store.state.encounters.syncing

      if (!isSyncing) {
        Vue.store.dispatch('loadData')
      }

      Vue.store.commit('encounters/SET_SYNCING', false)
    }

    return data.access
  },
}
