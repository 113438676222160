<template>
  <v-icon
    :disabled="disabled"
    :color="computedColor"
    :class="noPos ? '' : 'help-icon'"
    @click="iconClicked($event)"
  >
    {{ icon }}
  </v-icon>
</template>

<script>
import { mdiInformation } from '@mdi/js'

export default {
  props: {
    icon: {
      type: String,
      default: mdiInformation,
    },
    color: {
      type: String,
      default: 'warning',
    },
    title: {
      type: String,
      default: null,
    },
    body: {
      type: String,
      default: null,
    },
    subTitle: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noPos: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedColor() {
      return this.color !== 'default' ? this.color : null
    },
  },
  methods: {
    iconClicked(event) {
      this.$emit('click', event)
      if (this.body) {
        this.$root.confirm({
          title: this.title,
          subTitle: this.subTitle,
          body: this.body,
          large: true,
          html: true,
          cancel: false,
          persistent: false,
        })
      }
    },
  },
}
</script>

<style lang="scss">
.help-icon {
  position: absolute !important;
  z-index: 1;
  top: -2px;
  right: 4px;
  padding: 4px !important;
  width: 44px;
  height: 44px;
  .v-icon__svg {
    width: 36px;
    height: 36px;
  }
}
.note-section.has-accordion > .row >.help-icon {
  top: -4px;
  right: 48px;
}
.v-tabs .v-window-item >.help-icon {
  right: -10px;
  margin-top: -16px;
}
</style>
