import { mdiFileImage, mdiPaperclip } from '@mdi/js'
import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    practiceTypes: {
      items: [
        {
          title: 'Wound',
          encounterRoute: 'encounter-wound-care',
          noteRoute: 'encounter-note-wound-care',
          id: 1,
        },
        {
          title: 'Dermatology',
          encounterRoute: 'encounter-wound-care',
          noteRoute: 'encounter-note-wound-care',
          id: 6,
        },
        {
          title: 'G-Tube',
          encounterRoute: 'encounter-wound-care',
          noteRoute: 'encounter-note-wound-care',
          id: 5,
        },
        {
          title: 'Podiatry',
          encounterRoute: 'encounter-podiatry',
          noteRoute: 'encounter-note-podiatry',
          id: 7,
        },
        {
          title: 'Primary Care',
          encounterRoute: 'encounter-primary-care',
          noteRoute: 'encounter-note-primary-care',
          id: 4,
        },
        {
          title: 'Psychiatry',
          encounterRoute: 'encounter-psychiatry',
          noteRoute: 'encounter-note-psychiatry',
          id: 2,
        },
        {
          title: 'Psychology',
          encounterRoute: 'encounter-psychology',
          noteRoute: 'encounter-note-psychology',
          id: 3,
        },
      ],
    },
    practiceTypeTemplates: {
      items: [
        {
          title: 'Wound Care',
          id: 1,
        },
        {
          title: 'Podiatry',
          id: 7,
        },
        {
          title: 'Primary Care',
          id: 4,
        },
        {
          title: 'Psychiatry',
          id: 2,
        },
        {
          title: 'Psychology',
          id: 3,
        },
      ],
    },
    bucketTypes: {
      items: [
        {
          title: 'Facesheets',
          value: 'facesheets',
          icon: mdiPaperclip,
          pdf: true,
        },
        {
          title: 'Consents',
          value: 'consents',
          icon: mdiPaperclip,
          pdf: true,
        },
        {
          title: 'Orders',
          value: 'orders',
          icon: mdiPaperclip,
          pdf: true,
        },
        {
          title: 'Images',
          value: 'images',
          icon: mdiFileImage,
          pdf: false,
        },
      ],
    },
    query: {
      items: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    },
    sex: {
      items: [
        {
          label: 'Male',
          value: 'Male',
        },
        {
          label: 'Female',
          value: 'Female',
        },
      ],
    },
    procedures: {
      items: [
        { title: '[100] Muscle tissue debridement performed by surgical excision of devitalized Muscle, fascia, and tendon tissue.', id: 100 },
        { title: '[110] Muscle tissue debridement performed by surgical excision of devitalized skin, subcutaneous, muscle, fascia, and tendon tissue.', id: 110 },
        { title: '[120] Muscle tissue debridement performed by surgical excision of devitalized subcutaneous, muscle, fascia, and tendon tissue.', id: 120 },
        { title: '[130] Subcutaneous tissue debridement performed by surgical excision of devitalized subcutaneous tissue.', id: 130 },
        { title: '[140] Bone tissue debridement performed by surgical excision of devitalized bone tissue.', id: 140 },
        { title: '[141] Bone tissue debridement performed by surgical excision of devitalized cartilage tissue.', id: 141 },
        { title: '[142] Bone tissue debridement performed by surgical excision of devitalized muscle, fascia, tendon and bone tissue.', id: 142 },
        { title: '[150] Chemical cauterization of hypergranulation tissue employing silver nitrate application.', id: 150 },
        { title: '[170] Simple incision and drainage of abscess was performed.', id: 170 },
        { title: '[171] Complex incision and drainage of abscess was performed.', id: 171 },
        { title: '[172] Puncture aspiration of abscess.', id: 172 },
        { title: '[173] Puncture aspiration of bulla/cyst.', id: 173 },
        { title: '[174] Puncture aspiration of hematoma.', id: 174 },
        { title: '[175] Excisional Bone Biopsy.', id: 175 },
        { title: '[176] Surgical Drain Removal.', id: 176 },
        { title: '[177] Application of skin substitute to trunk, arms, legs.', id: 177 },
        { title: '[183] Removal of skin lesion < 0.5 cm².', id: 183 },
        { title: '[184] Callus Excision of Wound Margin.', id: 184 },
        { title: '[190] Biopsy of wound was performed.', id: 190 },
        { title: '[195] Application of multi-layer compression system; leg (below knee), including ankle and foot.', id: 195 },
        { title: '[200] Biopsy of lesion was performed.', id: 200 },
        { title: '[300] Emergent Replacement of Gastrostomy Tube.', id: 300 },
        { title: '[301] Emergent Replacement of Gastrostomy Tube with injection of contrast.', id: 301 },
        { title: '[311] Medicaid ONLY: Replacement of Gastrostomy Tube performed.', id: 311 },
        { title: '[312] Medicaid ONLY: Replacement of Gastrostomy Tube with injection of contrast.', id: 312 },
        { title: '[321] Medicare Non Part A: Replacement of Gastrostomy Tube performed.', id: 321 },
        { title: '[322] Medicare Non Part A: Replacement of Gastrostomy Tube with injection of contrast.', id: 322 },
        { title: '[331] Medicare Part A: Replacement of Gastrostomy Tube performed.', id: 331 },
        { title: '[332] Medicare Part A: Replacement of Gastrostomy Tube with injection of contrast.', id: 332 },
        { title: '[340] Removal of Gastrostomy Tube.', id: 340 },
        { title: '[400] Suprapubic cystostomy replacement, simple.', id: 400 },
        { title: '[401] Suprapubic cystostomy replacement, complex.', id: 401 },
        { title: '[500] Removal of nail plate.', id: 500 },
        { title: '[501] Removal of additional nail plate.', id: 501 },
        { title: '[502] Removal of nail bed/matrix for permanent removal.', id: 502 },
        { title: '[503] Foot - Callus shaving, 1 lesion.', id: 503 },
        { title: '[504] Foot - Callus shaving, 2 to 4 lesions.', id: 504 },
        { title: '[505] Callus shaving, diabetic & non-diabetic foot of 5 or greater lesions.', id: 505 },
        { title: '[520] Suture removal.', id: 520 },
        { title: '[521] Staple removal.', id: 521 },
        { title: '[998] Unlisted: see operative note below.', id: 998 },
        { title: '[999] None.', id: 999 },
      ],
    },
    woundProcedures: {
      items: [
        { title: '[100] Muscle tissue debridement performed by surgical excision of devitalized Muscle, fascia, and tendon tissue.', id: 100 },
        { title: '[110] Muscle tissue debridement performed by surgical excision of devitalized skin, subcutaneous, muscle, fascia, and tendon tissue.', id: 110 },
        { title: '[120] Muscle tissue debridement performed by surgical excision of devitalized subcutaneous, muscle, fascia, and tendon tissue.', id: 120 },
        { title: '[130] Subcutaneous tissue debridement performed by surgical excision of devitalized subcutaneous tissue.', id: 130 },
        { title: '[140] Bone tissue debridement performed by surgical excision of devitalized bone tissue.', id: 140 },
        { title: '[141] Bone tissue debridement performed by surgical excision of devitalized cartilage tissue.', id: 141 },
        { title: '[142] Bone tissue debridement performed by surgical excision of devitalized muscle, fascia, tendon and bone tissue.', id: 142 },
        { title: '[150] Chemical cauterization of hypergranulation tissue employing silver nitrate application.', id: 150 },
        { title: '[170] Simple incision and drainage of abscess was performed.', id: 170 },
        { title: '[171] Complex incision and drainage of abscess was performed.', id: 171 },
        { title: '[172] Puncture aspiration of abscess.', id: 172 },
        { title: '[173] Puncture aspiration of bulla/cyst.', id: 173 },
        { title: '[174] Puncture aspiration of hematoma.', id: 174 },
        { title: '[175] Excisional Bone Biopsy.', id: 175 },
        { title: '[176] Surgical Drain Removal.', id: 176 },
        { title: '[177] Application of skin substitute to trunk, arms, legs.', id: 177 },
        { title: '[183] Removal of skin lesion < 0.5 cm².', id: 183 },
        { title: '[184] Callus Excision of Wound Margin.', id: 184 },
        { title: '[190] Biopsy of wound was performed.', id: 190 },
        { title: '[195] Application of multi-layer compression system; leg (below knee), including ankle and foot.', id: 195 },
        { title: '[400] Suprapubic cystostomy replacement, simple.', id: 400 },
        { title: '[401] Suprapubic cystostomy replacement, complex.', id: 401 },
        { title: '[500] Removal of nail plate.', id: 500 },
        { title: '[501] Removal of additional nail plate.', id: 501 },
        { title: '[502] Removal of nail bed/matrix for permanent removal.', id: 502 },
        { title: '[503] Foot - Callus shaving, 1 lesion.', id: 503 },
        { title: '[504] Foot - Callus shaving, 2 to 4 lesions.', id: 504 },
        { title: '[505] Callus shaving, diabetic & non-diabetic foot of 5 or greater lesions.', id: 505 },
        { title: '[520] Suture removal.', id: 520 },
        { title: '[521] Staple removal.', id: 521 },
        { title: '[999] None.', id: 999 },
      ],
    },
    dermProcedures: {
      items: [
        { title: '[150] Chemical cauterization of hypergranulation tissue employing silver nitrate application.', id: 150 },
        { title: '[172] Puncture aspiration of abscess.', id: 172 },
        { title: '[173] Puncture aspiration of bulla/cyst.', id: 173 },
        { title: '[174] Puncture aspiration of hematoma.', id: 174 },
        { title: '[183] Removal of skin lesion < 0.5 cm².', id: 183 },
        { title: '[184] Callus Excision of Wound Margin.', id: 184 },
        { title: '[200] Biopsy of lesion was performed.', id: 200 },
        { title: '[500] Removal of nail plate.', id: 500 },
        { title: '[501] Removal of additional nail plate.', id: 501 },
        { title: '[502] Removal of nail bed/matrix for permanent removal.', id: 502 },
        { title: '[503] Foot - Callus shaving, 1 lesion.', id: 503 },
        { title: '[504] Foot - Callus shaving, 2 to 4 lesions.', id: 504 },
        { title: '[505] Callus shaving, diabetic & non-diabetic foot of 5 or greater lesions.', id: 505 },
        { title: '[520] Suture removal.', id: 520 },
        { title: '[521] Staple removal.', id: 521 },
        { title: '[998] Unlisted: see operative note below.', id: 998 },
        { title: '[999] None.', id: 999 },
      ],
    },
    gtubeProcedures: {
      items: [
        { title: '[150] Chemical cauterization of hypergranulation tissue employing silver nitrate application.', id: 150 },
        { title: '[300] Emergent Replacement of Gastrostomy Tube.', id: 300 },
        { title: '[301] Emergent Replacement of Gastrostomy Tube with injection of contrast.', id: 301 },
        { title: '[311] Medicaid ONLY: Replacement of Gastrostomy Tube performed.', id: 311 },
        { title: '[312] Medicaid ONLY: Replacement of Gastrostomy Tube with injection of contrast.', id: 312 },
        { title: '[321] Medicare Non Part A: Replacement of Gastrostomy Tube performed.', id: 321 },
        { title: '[322] Medicare Non Part A: Replacement of Gastrostomy Tube with injection of contrast.', id: 322 },
        { title: '[331] Medicare Part A: Replacement of Gastrostomy Tube performed.', id: 331 },
        { title: '[332] Medicare Part A: Replacement of Gastrostomy Tube with injection of contrast.', id: 332 },
        { title: '[340] Removal of Gastrostomy Tube.', id: 340 },
        { title: '[400] Suprapubic cystostomy replacement, simple.', id: 400 },
        { title: '[401] Suprapubic cystostomy replacement, complex.', id: 401 },
        { title: '[999] None.', id: 999 },
      ],
    },
    locations: {
      items: [
        { title: 'Abdomen', id: 1 },
        { title: 'Arm', id: 2 },
        { title: 'Back', id: 3 },
        { title: 'Ball of Foot', id: 4 },
        { title: 'Buttock', id: 5 },
        { title: 'Calf', id: 6 },
        { title: 'Cervical Spine', id: 7 },
        { title: 'Cheek', id: 8 },
        { title: 'Chest', id: 9 },
        { title: 'Chin', id: 10 },
        { title: 'Coccyx', id: 11 },
        { title: 'Ear', id: 12 },
        { title: 'Earlobe', id: 13 },
        { title: 'Elbow', id: 14 },
        { title: 'Eye', id: 15 },
        { title: 'Eyebrow', id: 16 },
        { title: 'Eyelid', id: 17 },
        { title: 'Finger', id: 18 },
        { title: 'Foot', id: 19 },
        { title: 'Forearm', id: 20 },
        { title: 'Forehead', id: 21 },
        { title: 'Groin', id: 54 },
        { title: 'Hand', id: 22 },
        { title: 'Heel', id: 23 },
        { title: 'Index Finger', id: 24 },
        { title: 'Ischium', id: 25 },
        { title: 'Knee', id: 26 },
        { title: 'Leg', id: 27 },
        { title: 'Lip', id: 28 },
        { title: 'Lumbar Spine', id: 29 },
        { title: 'Malleolus', id: 30 },
        { title: 'Mouth', id: 31 },
        { title: 'Nail Bed', id: 32 },
        { title: 'Neck', id: 33 },
        { title: 'Nose', id: 34 },
        { title: 'Occiput', id: 35 },
        { title: 'Palm', id: 36 },
        { title: 'Sacrococcyx', id: 37 },
        { title: 'Sacrum', id: 38 },
        { title: 'Scalp', id: 39 },
        { title: 'Scapula', id: 40 },
        { title: 'Scrotum', id: 41 },
        { title: 'Shin', id: 42 },
        { title: 'Shoulder', id: 43 },
        { title: 'Temple', id: 44 },
        { title: 'Thigh', id: 45 },
        { title: 'Thoracic Spine', id: 46 },
        { title: 'Thumb', id: 47 },
        { title: 'Tibia', id: 48 },
        { title: 'Toe', id: 49 },
        { title: 'Tracheostomy', id: 50 },
        { title: 'Trochanter', id: 51 },
        { title: 'Upper Arm', id: 52 },
        { title: 'Wrist', id: 53 },
      ],
    },
    balloonCc: {
      items: [
        { title: '05 cc' },
        { title: '10 cc' },
        { title: '15 cc' },
        { title: '20 cc' },
        { title: '25 cc' },
        { title: '30 cc' },
      ],
    },
    replacementSize: {
      items: [
        { title: '12' },
        { title: '14' },
        { title: '16' },
        { title: '18' },
        { title: '20' },
        { title: '22' },
        { title: '24' },
        { title: '26' },
        { title: '28' },
        { title: '30' },
        { title: '32' },
        { title: '34' },
      ],
    },
    psychologyDiagnoses: {
      items: [
        { title: 'Generalized anxiety disorder.' },
        { title: 'Adjustment disorder with mixed anxiety and depressed mood.' },
        { title: 'Adjustment disorder with anxiety.' },
        { title: 'Major depressive disorder, recurrent, moderate.' },
        { title: 'Adjustment disorder, unspecified.' },
        { title: 'Anxiety disorder, unspecified.' },
        { title: 'Adjustment disorder with depressed mood.' },
        { title: 'Post-traumatic stress disorder, chronic.' },
        { title: 'Dysthymic disorder.' },
        { title: 'Post-traumatic stress disorder, unspecified.' },
        { title: 'Major depressive disorder, single episode, moderate.' },
        { title: 'Major depressive disorder, recurrent, mild.' },
        { title: 'Major depressive disorder, single episode, unspecified.' },
        { title: 'Attention-deficit hyperactivity disorder, combined type.' },
        { title: 'Personality disorder, unspecified.' },
        { title: 'Other natural environment type phobia.' },
        { title: 'Adjustment disorder w/ mixed disturb of emotions and conduct.' },
        { title: 'Catatonic disorder due to known physiological condition.' },
        { title: 'Major depressive disorder, recurrent severe without psychotic features.' },
        { title: 'Panic disorder without agoraphobia.' },
        { title: 'Attention-deficit hyperactivity disorder, predominantly inattentive type.' },
        { title: 'Major depressive disorder, single episode, mild.' },
        { title: 'Mixed obsessional thoughts and acts.' },
        { title: 'Childhood autism.' },
        { title: 'Other' },
      ],
    },
  },
  getters: {
    getField,
    bucketTypes(state) {
      return state.bucketTypes.items
    },
    practiceTypes(state) {
      return state.practiceTypes.items
    },
    practiceTypeTemplates(state) {
      return state.practiceTypeTemplates.items
    },
    query(state) {
      return state.query.items
    },
    sex(state) {
      return state.sex.items
    },
    procedures(state) {
      return state.procedures.items
    },
    woundProcedures(state) {
      return state.woundProcedures.items
    },
    dermProcedures(state) {
      return state.dermProcedures.items
    },
    gtubeProcedures(state) {
      return state.gtubeProcedures.items
    },
    balloonCc(state) {
      return state.balloonCc.items
    },
    replacementSize(state) {
      return state.replacementSize.items
    },
    psychologyDiagnoses(state) {
      return state.psychologyDiagnoses.items
    },
    locations(state) {
      return state.locations.items
    },
    practiceTypeFromId: state => id => {
      // Find the index
      const index = state.practiceTypes.items.findIndex(x => x.id === id)

      return index !== -1 ? state.practiceTypes.items[index] : null
    },
    bucketTypeFromValue: state => value => {
      // Find the index
      const index = state.bucketTypes.items.findIndex(x => x.value === value)

      return index !== -1 ? state.bucketTypes.items[index] : null
    },
    procedureFromId: state => id => {
      // Find the index
      const index = state.procedures.items.findIndex(x => x.id === id)

      return index !== -1 ? state.procedures.items[index].title : null
    },
    locationFromId: state => id => {
      // Find the index
      const index = state.locations.items.findIndex(x => x.id === id)

      return index !== -1 ? state.locations.items[index].title : null
    },
  },
  mutations: {
    updateField,
  },
  actions: {
  },
}
