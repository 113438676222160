// import encounterAttachments from './encounterAttachments'
import auth from './auth'
import baseData from './baseData'
import comments from './comments'
import encounters from './encounters'
import facilities from './facilities'
import patients from './patients'
import route from './route'
import users from './users'

export default {
  // encounterAttachments,
  auth,
  baseData,
  comments,
  encounters,
  facilities,
  patients,
  users,
  route,
}
