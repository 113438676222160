<template>
  <div
    :class="`radio-group-container ${row ? 'radio-buttons-row' : ''} ${noteRequiredField ? 'required-field' : ''}`"
    style="width: 100%; position: relative"
  >
    <label
      :class="`${disabled ? 'radio-buttons-row--is-disabled' : ''} ${!label ? 'empty-label' : ''} ${labelTitle ? 'label-title' : ''}`"
    >
      <strong
        v-if="noteRequiredField"
        class="error--text"
      >*</strong>
      {{ label }} <span v-if="disabled && disabledCustomText" style='color: orange'>({{ disabledCustomText }})</span>
    </label>
    <v-radio-group
      v-model="selectedValue"
      :disabled="disabled"
      :rules="computedRules"
      :row="computedRow"
      class="mt-0"
      :append-icon="computedAppendIcon"
      :prepend-icon="prependIcon"
      :hide-details="hideDetails"
      :class="computedColumns ? `columns-${computedColumns}` : ''"
      @change="$emit('change', $event); $emit('input', $event)"
      @click="$emit('click', $event)"
      @click:append="clickAppendIcon($event)"
    >
      <strong
        v-if="noteRequiredField && !label && row"
        class="error--text"
        style="position: relative; top: 15px; height: 15px;"
      >*</strong>
      <div
        :class="`${computedRow ? 'd-flex flex-wrap' : ''} ${columnSort && computedColumns ? 'flex-column' : ''}`"
        :style="columnSort ? `max-height: ${computedFieldHeight}px` : ''"
      >
        <v-radio
          v-for="(item, i) in items"
          :key="`item-${i}`"
          :value="item[computedValue]"
          :disabled="item.disabled"
        >
          <template v-slot:label>
            <div v-html="item[computedLabel]"></div>
          </template>
        </v-radio>
      </div>
    </v-radio-group>
    <slot></slot>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: null,
    },
    value: {
      type: [String, Boolean, Number],
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    columnSort: {
      type: Boolean,
      default: false,
    },
    column: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: String,
      default: null,
    },
    row: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemValue: {
      type: String,
      default: null,
    },
    itemLabel: {
      type: String,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    labelTitle: {
      type: Boolean,
      default: false,
    },
    disabledCustomText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedValue: this.value,
      icons: { mdiClose },
    }
  },
  computed: {
    noteRequiredField() {
      return !this.disabled && this.required && !this.selectedValue && this.selectedValue !== 0 && this.selectedValue !== false
    },
    isTrueFalse() {
      return (this.items && this.items.length === 2 && this.items[0].value === true && this.items[1].value === false)
    },
    computedRules() {
      if (!this.required || this.disabled) return []

      return this.isTrueFalse ? this.$validationRules.requiredBool : this.$validationRules.required
    },
    computedRow() {
      return !this.computedColumns
    },
    computedColumns() {
      if (this.column || this.columns !== null) {
        return this.columns ? this.columns : 1
      }

      return false
    },
    computedValue() {
      if (this.itemValue) return this.itemValue
      if (this.items && this.items.length) {
        if (typeof this.items[0].id !== 'undefined') return 'id'
        if (typeof this.items[0].value !== 'undefined') return 'value'
        if (typeof this.items[0].title !== 'undefined') return 'title'
        if (typeof this.items[0].label !== 'undefined') return 'label'
      }

      return 'value'
    },
    computedLabel() {
      if (this.itemLabel) return this.itemLabel
      if (this.items && this.items.length) {
        if (typeof this.items[0].title !== 'undefined') return 'title'
      }

      return 'label'
    },
    computedFieldHeight() {
      return Math.ceil(this.items.length / this.computedColumns) * (this.hideDetails ? 49 : 51)
    },
    computedAppendIcon() {
      if (this.appendIcon) return this.appendIcon
      if (this.clearable) {
        if (!this.$custom.isEmpty(this.selectedValue)) return this.icons.mdiClose

        return ' '
      }

      return null
    },
  },
  watch: {
    value() {
      this.selectedValue = this.value
    },
  },
  methods: {
    clickAppendIcon(event) {
      if (this.clearable) {
        this.selectedValue = null
        this.$emit('change', null)
        this.$emit('input', null)
      }
      this.$emit('click:append', event)
    },
  },
}
</script>

<style lang="scss">
.radio-buttons-row {
  display: flex;
  justify-content: space-between;
  > label {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.68);
    &.radio-buttons-row--is-disabled {
      color: rgba(0, 0, 0, 0.5);
    }
  }
  > .v-input {
    margin-top: -11px !important;
    .v-input__control {
      margin-bottom: 0 !important;
    }
    .v-input__slot .flex-wrap {
      flex-wrap: nowrap !important;
      .v-radio {
        min-width: unset;
      }
    }
  }
}
.v-input--radio-group{
  .v-input__append-outer {
    .v-icon {
      // color: var(--v-primary-base);
      .v-icon__svg {
        width: 27px;
        height: 27px;
      }
    }
  }
}
</style>
